import { Howl } from 'howler';

export default class AudioObj {
	constructor(props) {

		const urlPrefix = `res/audio/`;
		this.id = props.id;
		this.url = props.url;
		this.howl = new Howl({
			src: [urlPrefix + props.url],
			format: ['mp3'],
			preload: true,
			onload: () => this.loaded(props.itemLoaded),
			volume: 1
		});
	}

	loaded = (itemLoaded) => {
		itemLoaded(this.id, this.howl.buffer);
	}


	play = (delay) => {
		this.howl.volume(0);
		if (delay > 0) {
			if ((this.howl.duration() * 1000) - delay < 0) {
			} else {
				this.howl.seek(delay * 0.001);
				this.howl.play();
				this.howl.fade(0, 1.0, 10);
			}
		} else {
			this.howl.play();
			this.howl.fade(0, 1.0, 10);
		}
	}

	stop = () => {
		this.howl.fade(1.0, 0, 120);
		setTimeout(() => { this.howl.stop(); }, 120);
	}
}
