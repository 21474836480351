import React, { Component } from 'react'
import HTML5Backend from 'react-dnd-html5-backend'
import { DragDropContext } from 'react-dnd'
import async from 'async'
import Instrument from './ui/Instrument'
import AddInstrument from './addInstrument'
import TimeLine from './ui/Timeline'
import GenArr from './ui/GenArr'
import GenSelections from './Data/GenSelections'

import { Arrange } from './Player/Gather/Arrange.jsx'
import EnterDuration from './ui/EnterDur'
import ScheduleGlobal from './Player/Scheduler/Scheduler'
import Loading from './Data/loading'
import DurErr from './ui/DurErr'

import Intro from './ui/Intro.jsx'
import ColoredLinearProgress from './ui/progressBar.jsx'

import { getFromServer } from './Data/GetFromServer'

import instrumentCodes from '../static/instrumentCodes.json';

class App extends Component {
  constructor () {
    super()

    this.connectCount = 0
    this.pass = 2
    this.timeLineRef = React.createRef()
    this.start = { width: 0, height: 0 }
    this.gMaxMinDur = 0
    this.scheds = []

    this.percent = 0
    this.playStartTime = 0
    this.playEndTime = 0
    this.pauseTime = 0
    this.progressTimer
    this.pauseStart = 0
    this.pauseEnd = 0
    this.GenSels
    this.instsReady = []
  }

  state = {
    selections: [],
    selectionsData: [],
    pages: [],
    boxHeight: 120,
    connected: false,
    playMode: false,
    minimumDuration: 0,
    durationSet: 0,
    playing: false,
    changeDuration: false,
    loading: false,
    message: 'Loading',
    remove: '',
    showIntro: true,
    percent: 0,
    timePassed: 0,
    instEditing: null,
    showGenArr: false,
    genInstN: 1,
    genPageN: 1,
    genIncPiano: true,
    genSelections: [],
    pianoDurErrBool: false,
    removePianoBool: false,
    autoSelections: [],
    autoSelsRemoved: 0,
    generating: false,
    itemToRemove: {},
    durErr: {
      overDur: 0,
      show: false,
      inst: '',
      lastPage: -4
    },

    timeUnit: 0,
    readyToPlay: false,
    notSelected: [],
    audioLoading: false,
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    zoomed: false,
    randomSizes: false
  }

  calculateTimeUnit = override => {
    if (override <= 0 || !override) {
      var timeUnit = (window.innerWidth - 50) / this.state.durationSet

      this.setState({ timeUnit })
    } else {
      var timeUnit = (window.innerWidth - 50) / override

      this.setState({ timeUnit })
    }
  }

  componentDidMount () {
    window.addEventListener('resize', this.handleResize)
    this.GenSels = new GenSelections()
    this.GenSels.init()

        this.setState({ notSelected: instrumentCodes })
        this.forceUpdate()

  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = e => {
    var that = this

    clearTimeout(this.resizeTimer)
    this.resizeTimer = setTimeout(function () {
      that.setState({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight
      })

      that.calculateTimeUnit()
      if (that.scheds) {
        if (that.scheds.length > 0) {
          that.scheds.map(sched => {
            sched.resize(window.innerWidth - 50, 0)
          })
        }
      }
    }, 250)
  }

  zoomIn = () => {
    let prevHeight = this.state.boxHeight
    if (prevHeight > 70) {
      prevHeight -= 10
      this.setState({ boxHeight: prevHeight })

      if (this.scheds.length > 0) {
        this.scheds.map(sched => {
          sched.resize(window.innerWidth - 50, prevHeight)
        })
      }
    }
    this.setState({ zoomed: true })
  }

  addInstruments = sels => {
    this.setState({
      selections: sels.map(sel => {
        let numberIndex = -1
        for (const i in sel.inst) {
          if (!isNaN(parseInt(sel.inst[i]))) {
            numberIndex = i
          }
        }

        if (numberIndex != -1) {
          let temp = sel.inst
          temp = `${sel.inst.slice(0, numberIndex)} ${sel.inst.slice(
            numberIndex
          )}`
          sel.inst = temp
        }

        return sel.inst
      })
    })
    this.setState({ autoSelections: sels })
  }

  zoomOut = () => {
    let prevHeight = this.state.boxHeight
    if (prevHeight < 190) {
      prevHeight += 10
      this.setState({ boxHeight: prevHeight })
    }

    if (this.scheds) {
      if (this.scheds.length > 0) {
        this.scheds.map(sched => {
          sched.resize(window.innerWidth - 50, prevHeight)
        })
      }
    }
    this.setState({ zoomed: true })
  }

  zoomAccepted = () => {
    setTimeout(() => {
      this.setState({ zoomed: false })
    }, 10)
  }

  hideIntro = () => {
    this.setState({ showIntro: false })
  }

  showIntro = () => {
    this.setState({ showIntro: true })
  }

  onSuccessful = () => {
    this.connectCount++
    if (this.connectCount == this.pass) {
      this.setState({ connected: true })
    }
  }

  passSelections = (selections, inst) => ({ data: selections, inst })

  undo = () => {}

  removeInst = instrument => {
    this.setState(prevState => {
      const index = prevState.selections.indexOf(instrument)
      prevState.selections.splice(index, 1)
      return prevState
    })

    // new Promise((resolve, reject) => {
    //   getFromServer('instrumentCodes.json', 3000, resolve)
    // }).then(data => {
      let data = instrumentCodes;
      this.setState(prevState => {
        var notSelected = prevState.notSelected
        var toAdd = data.find(d => {
          return d.key == instrument
        })

        if (toAdd.key == 'piano') {
          notSelected.push(toAdd)
        } else {
          var index = notSelected.findIndex(nS => {
            return parseInt(nS.id) > parseInt(toAdd.id)
          })
          notSelected.splice(index, 0, toAdd)
        }
        prevState.notSelected = notSelected
        return prevState
      })
    // })
  }

  preLoadAudio = (selections, check) => {
    // Preload audio ordering:
    // preload first page of sound.
    // measure how long it took.
    // if it takes a long time, then calculate projected amount of time necessary for remaining files.

    const time = 0
    // add a timer;
    check(null, `completed in ${0} ms`)
  }

  play = () => {
    this.setState({ pause: false })
    this.audioLoading(true)
    const that = this

    this.scheds = []
    new Promise((resolve, reject) => {
      //Arrange Arrangement
      var temp = []
      this.state.selections.map(sel => {
        temp.push(
          this.state.selectionsData.find(selDat => {
            return selDat.name === sel
          })
        )
      })

      this.setState({ selectionsData: temp })
      Arrange(temp, resolve)
    }).then(scheduleData => {
      new Promise((resolveA, reject) => {
        //  Map schedule data;

        const schedDownloadInfo = []
        if (scheduleData.length > 1) {
          const indexedScheduleData = scheduleData.map((schedData, index) => ({
            index,
            schedData
          }))

          async.map(
            indexedScheduleData,
            (schedDat, done) => {
              this.scheds[schedDat.index] = new ScheduleGlobal(
                schedDat.schedData,
                schedDat.schedData[0].inst
              )
              schedDownloadInfo[schedDat.index] = this.scheds[
                schedDat.index
              ].run(this.loading, done)
            },
            (err, results) => {
              if (err) {
                console.log(err)
                reject(null)
                this.audioLoading(false)
              } else {
                resolveA(results)
              }
            }
          )
        } else {
          this.scheds[0] = new ScheduleGlobal(
            scheduleData[0],
            scheduleData[0].inst
          )
          schedDownloadInfo[0] = this.scheds[0].run(this.loading, resolveA)
        }
      }).then(speeds => {
        new Promise((resolveB, rejectB) => {
          this.loading(true, 'Optimising Streaming Smoothness!')

          let min = 10000000
          for (const i in speeds) {
            if (speeds[i] < min) {
              min = speeds[i]
            }
          }

          async.map(
            this.scheds,
            (sched, done) => {
              sched.caluclateDownloadStartPoints(min, done)
            },
            (err, results) => {
              if (err) {
                console.log(err)
                this.loading(false, '')
                rejectB(err)
                this.audioLoading(false)
              } else {
                this.loading(false, 'Finished Optimisation.')
                resolveB(results)
              }
            }
          )

          // that.setState({ playing: true });
        })
          .then(results => {
            for (const i in this.scheds) {
              this.scheds[i].prepareToPlay(this.loading, this.ready)
            }
          })
          .catch(err => {
            console.log(err)
            this.audioLoading(false)
          })
      })
    })
  }

  ready = inst => {
    this.audioLoading(false)
    let found = false
    this.instsReady.find(instR => {
      if (instR == inst) {
        found = true
      }
    })

    if (!found) {
      this.instsReady.push(inst)
      if (this.instsReady.length == this.state.selections.length) {
        this.scheds.map(sched => {
          sched.play(this.loading, this.finished)
        })
        this.playStartTime = performance.now()
        this.playEndTime = performance.now() + this.state.durationSet * 1000
        this.startProgressTick()
        this.audioLoading(false)
      }
    }
  }

  changeDuration = () => {
    this.setState({ changeDuration: true })
  }

  openGenPage = () => {
    this.setState({ showGenArr: true })
  }

  acceptDuration = () => {
    if (
      typeof this.state.durationSet === 'undefined' ||
      isNaN(this.state.durationSet)
    ) {
      return
    }
    this.setState({ changeDuration: false })
    this.render()
  }

  setGlobalMinMaxDur = newMinMaxDur => {
    this.gMaxMinDur = newMinMaxDur
  }

  getGlobalMinMaxDur = () => this.gMaxMinDur

  addToSelection = inst => {
    this.setState(prevState => {
      const items = prevState.selections
      items.push(inst.key)
      return { items }
    })

    this.updateNotSelected(inst)
  }

  updateNotSelected = inst => {
    if (typeof inst == 'undefined') {
      return
    }
    let indexToRemove = 0
    for (const i in this.state.notSelected) {
      if (this.state.notSelected[i].id == inst.id) {
        indexToRemove = i
      }
    }

    this.setState(prevState => {
      var notSelected = prevState.notSelected
      notSelected.splice(indexToRemove, 1)
      prevState.notSelected = notSelected
      return prevState
    })
  }

  getSelections = (addedItems, inst, addedDurs) => {
    if (addedItems.length == 0) {
      return
    }
    this.setState(prevState => {
      const selections = prevState.selectionsData
      let index = -1
      if (selections.length > 0) {
        index = selections.map(selection => selection.name).indexOf(inst)
      }

      if (index == -1 || typeof index === 'undefined') {
        const newSelection = {
          name: inst,
          pages: addedItems,
          durs: addedDurs,
          minDur: 0,
          addedDur: 0
        }
        selections.push(newSelection)
      } else if (addedItems.length > 0 && addedDurs.length > 0) {
        selections[index].pages = addedItems
        selections[index].durs = addedDurs
      }
      return selections
    })
  }

  targetDurations = durations => {
    this.setState(prevState => {
      const selections = prevState.selectionsData
      let index = -1
      if (selections.length > 0) {
        index = selections
          .map(selection => selection.name)
          .indexOf(durations.name)
      }

      if (index == -1 || typeof index === 'undefined') {
      } else {
        selections[index].minDur = durations.minimumDuration
        selections[index].addedDur = durations.addedDuration
      }
      return selections
    })
  }

  getDurations = () => {
    const durations = this.state.selectionsData.map(selection => ({
      minDur: selection.minDur,
      addedDur: selection.addedDur
    }))

    let maxMinDur = 0
    let maxAddedDur = 0
    for (const i in durations) {
      if (durations[i].minDur > maxMinDur) {
        maxMinDur = durations[i].minDur
      }
      if (durations[i].addedDur > maxAddedDur) {
        maxAddedDur = durations[i].addedDur
      }
    }
    return { minDur: maxMinDur, addedDur: maxAddedDur }
  }

  setMinimumDuration = durations => {}

  passSelections = () => this.state.selectionsData

  gatherPages = inst => {
    if (typeof inst.value === 'undefined') {
      return
    }
    this.setState(prevState => {
      const pages = inst.value.map(page => page.id - 1)
      let index = -1
      const prevPages = prevState.pages

      if (prevPages.length < 1) {
        prevState.pages.push({ key: inst.key, value: pages })
      } else {
        prevPages.map((page, i) => {
          if (page.key === inst.key) index = i
        })

        if (index >= 0) {
          prevState.pages[index].value = pages
        } else {
          prevState.pages.push({ key: inst.key, value: pages })
        }
      }
    })
  }

  showDurErr = (overDur, inst, lastPage) => {
    this.loading(false, '')
    this.setState({
      durErr: {
        overDur,
        show: true,
        inst,
        lastPage
      }
    })
  }

  removeLastItem = (inst, id) => {
    this.setState({
      durErr: {
        overDur: 0,
        show: false,
        inst: '',
        lastPage: -4
      },
      itemToRemove: { inst, id }
    })
  }

  lastItemRemoved = () => {
    this.setState({ itemToRemove: {} })
  }

  resolveDur = () => {
    this.setState({
      durErr: {
        overDur: 0,
        show: false,
        inst: '',
        lastPage: -4
      }
    })
  }

  setDuration = (duration, pianoDurErr) => {
    if (typeof duration === 'undefined' || duration == 0 || isNaN(duration)) {
      return
    }
    this.calculateTimeUnit(duration)
    if (typeof pianoDurErr !== 'undefined') {
      if (pianoDurErr) {
        this.generate(this.state.genInstN, this.state.genPageN, true, duration)
      }
    }

    this.setState({
      durationSet: duration,
      pianoDurErrBool: false,
      durErr: {
        overDur: 0,
        show: false,
        inst: '',
        lastPage: -4
      }
    })
  }

  setPlay = () => {
    this.setState({ playMode: true, pause: false })
  }

  resume = () => {
    for (const i in this.scheds) {
      this.scheds[i].resume(this.finished)
    }
    this.pauseEnd = performance.now()
    this.pauseTime += this.pauseEnd - this.pauseStart
    this.setState({ playMode: true, pause: false })

    this.startProgressTick()
  }

  pause = () => {
    this.pauseStart = performance.now()
    this.stopProgressTick()
    for (const i in this.scheds) {
      this.scheds[i].pause()
    }
    this.setState({ pause: true })
  }

  zoom = e => {
    this.setState({ boxHeight: e.target.value })
  }

  setStop = () => {
    this.stopProgressTick()
    this.loading(false, '')
    this.instsReady = []
    this.setState({ playMode: false, percent: 0, timePassed: 0 })
    this.pauseTime = 0
    this.scheds.map((sched, i) => {
      if (typeof sched !== 'undefined') {
        sched.stop(this.deleteSched, i)
      }
    })
  }

  audioLoading = bool => {
    this.setState({ audioLoading: bool })
  }

  deleteSched = i => {
    this.scheds[i] = null
    const N = this.scheds.length
    var count = 0
    this.scheds.map(sched => {
      if (!sched) {
        count++
      }
    })
    if (count == N) {
      this.scheds = null
    }
  }

  loading = (loading, message, options) => {
    // loading: true or false;
    this.setState({ loading })
    this.setState({ loadingMsg: message })
    this.setState({ options })
  }

  progressBarValue = time => {
    const now = performance.now()
    const timePassed = now - this.pauseTime - this.playStartTime
    const percent =
      100 *
      (1 -
        (this.playEndTime - (this.playStartTime + timePassed)) /
          (this.state.durationSet * 1000))
    if (percent >= 100) {
      this.stopProgressTick()
    }
    this.setState({ percent, timePassed })
  }

  startProgressTick = () => {
    this.progressTimer = setInterval(this.progressBarValue, 300)
  }

  stopProgressTick = () => {
    clearTimeout(this.progressTimer)
  }

  instBeingEdited = inst => {
    this.setState({ instEditing: inst })
  }

  manualArrange = () => {
    this.setState({ showGenArr: false })
  }

  generate = (
    instN,
    pageN,
    incPiano,
    overrideDur,
    incSilences,
    randomSizes
  ) => {
    this.setState({
      generating: true,
      loading: true,
      selections: [],
      selectionsData: [],
      randomSizes: randomSizes
    })
    this.loading(true, 'Generating Arrangement...')
    this.setState({
      showGenArr: false,
      genInstN: instN,
      genPageN: pageN,
      genIncPiano: incPiano,
      selections: [],
      generating: true
    })
    new Promise((resolve, reject) => {
      if (overrideDur) {
        this.GenSels.generate(
          instN,
          pageN,
          overrideDur,
          incPiano,
          this.pianoDurErr,
          this.addInstruments,
          randomSizes,
          incSilences,
          resolve
        )
      } else {
        this.GenSels.generate(
          instN,
          pageN,
          this.state.durationSet,
          incPiano,
          this.pianoDurErr,
          this.addInstruments,
          randomSizes,
          incSilences,
          resolve
        )
      }
    })
      .then(selInsts => {
            var data = instrumentCodes;
            selInsts.map(inst => {
              this.updateNotSelected(
                data.find(d => {
                  return d.key === inst
                })
              )
            })
            this.setState({ generating: false })
            this.loading(false)
      })
      .catch(err => {
        console.log(err)
      })
  }

  pianoDurErr = pianoDur => {
    this.loading(false, '')
    this.setState({ pianoDurErrBool: true })
    this.setState({
      durErr: {
        overDur: pianoDur,
        show: true,
        inst: '',
        lastPage: ''
      }
    })
  }

  removePiano = () => {
    this.setState({ pianoDurErrBool: false, removePianoBool: true })
    this.generate(this.state.genInstN, this.genPageN, this.state.durationSet)
  }

  removeAutoSel = id => {
    this.setState(prevState => {
      prevState.autoSelections.splice(id - prevState.autoSelsRemoved, 1)
      prevState.autoSelsRemoved++
      if (prevState.autoSelections.length == 0) {
        prevState.autoSelsRemoved = 0
      }
      return prevState
    })
  }

  clear = () => {
    this.setState({
      selections: [],
      selectonsData: [],
      autoSelections: []
    })
      var data = instrumentCodes;
        this.setState({ notSelected: data })
  }

  render () {
    const instHeight = this.state.playMode ? '65px' : '60px'
    const selsInstUpdate = this.state.autoSelections.length > 0

    return (
      <div className='App'>
        {this.state.showIntro && <Intro hideMe={this.hideIntro} />}
        <EnterDuration
          setDuration={this.setDuration}
          changeDuration={this.state.changeDuration}
          acceptDuration={this.acceptDuration}
          openGenPage={this.openGenPage}
          showIntro={this.showIntro}
        />
        <DurErr
          overDur={this.state.durErr.overDur}
          show={this.state.durErr.show}
          selDur={this.state.durationSet}
          setDuration={this.setDuration}
          undo={this.undo}
          pianoDurErr={this.state.pianoDurErrBool}
          removePiano={this.removePiano}
          inst={this.state.durErr.inst}
          lastPage={this.state.durErr.lastPage}
          removeLastItem={this.removeLastItem}
        />
        <Loading
          loading={this.state.loading}
          message={this.state.loadingMsg}
          options={this.state.options}
        />
        <GenArr
          show={this.state.showGenArr}
          generate={this.generate}
          manualArrange={this.manualArrange}
        />
        <div>
          {this.state.playMode && (
            <div
              className='ProgressBar'
              style={{
                position: 'fixed',
                bottom: '60px',
                left: '0',
                width: '100%',
                background: '#b6b6b6'
              }}
            >
              <ColoredLinearProgress
                variant='determinate'
                value={this.state.percent}
                height='15px'
              />
            </div>
          )}
          <TimeLine
            ref={this.timeLineRef}
            setPlay={this.setPlay}
            selections={this.state.selections}
            minimumDuration={this.state.minimumDuration}
            durationSet={this.state.durationSet}
            getDurations={this.getDurations}
            play={this.play}
            setStop={this.setStop}
            changeDuration={this.changeDuration}
            pause={this.pause}
            pauseState={this.state.pause}
            resume={this.resume}
            percent={this.percent}
            showIntro={this.showIntro}
            zoomIn={this.zoomIn}
            zoomOut={this.zoomOut}
            timePassed={this.state.timePassed}
            openGenPage={this.openGenPage}
            clear={this.clear}
            audioLoading={this.state.audioLoading}
            // passSelections={this.passSelections}
          />
          {/* <div className="slider-wrapper">
            <input className="scroll" type='range' min={20} max={200} onChange={this.zoom} />
          </div> */}
          <div
            className='instrumentArea'
            style={{
              position: 'fixed',
              top: '0',
              left: '0',
              right: '0',
              bottom: instHeight,
              overflow: 'auto',
              margin: '0'
            }}
          >
            {!this.state.generating && (
              <InstrumentList
                instEditing={this.state.instEditing}
                setMinimumDuration={this.setMinimumDuration}
                selections={this.state.selections}
                boxHeight={this.state.boxHeight}
                playMode={this.state.playMode}
                gatherPages={this.gatherPages}
                timeLineRef={this.timeLineRef}
                passSelections={this.passSelections}
                getSelections={this.getSelections}
                targetDurations={this.targetDurations}
                gMaxMinDur={this.gMaxMinDur}
                setGlobalMinMaxDur={this.setGlobalMinMaxDur}
                getGlobalMinMaxDur={this.getGlobalMinMaxDur}
                durationSet={this.state.durationSet}
                showDurErr={this.showDurErr}
                removeInst={this.removeInst}
                instBeingEdited={this.instBeingEdited}
                autoSelections={this.state.autoSelections}
                autoSelsAdded={selsInstUpdate}
                removeAutoSel={this.removeAutoSel}
                itemToRemove={this.state.itemToRemove}
                lastItemRemoved={this.lastItemRemoved}
                timeUnit={this.state.timeUnit}
                durErr={this.state.durErr}
                windowHeight={this.state.windowHeight}
                windowWidth={this.state.windowWidth}
                zoomed={this.state.zoomed}
                zoomAccepted={this.zoomAccepted}
                randomSizes={this.state.randomSizes}
              />
            )}
            <br />
            {!this.state.playMode && (
              <AddInstrument
                loading={this.state.loading}
                toRemove={this.state.autoSelections}
                addToSelection={this.addToSelection}
                data={this.state.notSelected}
                scrollTo={this.scrollTo}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}

const noSelectStyle = {
  MozUserSelect: 'none',
  WebkitUserSelect: 'none',
  msUserSelect: 'none'
}

const InstrumentList = props => {
  let turnOffEditing = false

  return (
    <div>
      {props.selections.map(selection => {
        if (props.instEditing != null) {
          if (selection.toLowerCase() == props.instEditing.toLowerCase()) {
            turnOffEditing = false
          } else {
            turnOffEditing = true
          }
        } else {
          turnOffEditing = false
        }

        let selId = -1

        let itemToRemove = -1
        if (typeof props.itemToRemove.inst !== 'undefined') {
          if (props.itemToRemove.inst == selection) {
            itemToRemove = props.itemToRemove.id
          }
        }

        let autoSelection
        if (typeof props.autoSelections !== 'undefined') {
          autoSelection = props.autoSelections.find((sel, i) => {
            if (sel.inst == selection) {
              selId = i
              return sel
            }
          })
          autoSelection = { sel: autoSelection, id: selId }
        }
        return (
          <Instrument
            key={selection}
            name={selection}
            setMinimumDuration={props.setMinimumDuration}
            selection={selection}
            boxHeight={props.boxHeight}
            playMode={props.playMode}
            pageSelections={props.gatherPages}
            timeLineRef={props.timeLineRef}
            passSelections={props.passSelections}
            getSelections={props.getSelections}
            targetDurations={props.targetDurations}
            gMaxMinDur={props.gMaxMinDur}
            setGlobalMinMaxDur={props.setGlobalMinMaxDur}
            getGlobalMinMaxDur={props.getGlobalMinMaxDur}
            durationSet={props.durationSet}
            showDurErr={props.showDurErr}
            removeInst={props.removeInst}
            style={noSelectStyle}
            turnOffEditing={turnOffEditing}
            instBeingEdited={props.instBeingEdited}
            autoSelection={autoSelection}
            autoSelsAdded={props.autoSelsAdded}
            removeAutoSel={props.removeAutoSel}
            itemToRemove={itemToRemove}
            lastItemRemoved={props.lastItemRemoved}
            timeUnit={props.timeUnit}
            durErr={props.durErr}
            windowWidth={props.windowWidth}
            windowHeight={props.windowHeight}
            zoomed={props.zoomed}
            zoomAccepted={props.zoomAccepted}
            randomSizes={props.randomSizes}
          />
        )
      })}
    </div>
  )
}

export default DragDropContext(HTML5Backend)(App)
