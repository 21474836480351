import React, { Component } from 'react'
import Tooltip from '@mui/material/Tooltip'

export default class AddInstrument extends Component {
  state = {
    mode: 0,
    instList: []
  }

  constructor (props) {
    super()
    this.addInstObj
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.setState(prevState => {
      prevState.instList = nextProps.data
      return prevState
    })

    if (this.addInstObj != null) {
      if (nextProps.loading) {
        this.addInstObj.classList.add('loading')
        this.addInstObj.classList.remove('finishedLoading')
      } else {
        this.addInstObj.classList.remove('loading')
        this.addInstObj.classList.add('finishedLoading')
      }
    }
  }

  componentDidMount () {
    // this.setState(prevState => {
    //     this.props.data.map(instrument => {
    //         prevState.instList.push(instrument);
    //     });
    // });
    this.addInstObj = document.getElementById('addInst')
  }

  add = () => {
    const prevMode = this.state.mode * -1 + 1
    this.setState({ mode: prevMode })
  }

  addInstrument = instName => {
    this.props.addToSelection(instName)
    //this.deleteItems(instName);
    this.setState({ mode: 0 })
  }

  deleteItems = instName => {
    let newAdd
    this.setState(prevState => {
      const items = prevState.instList
      const index = items.findIndex(item => item.id == instName.value)
      newAdd = items.splice(index, 1)
      return { items }
    })
  }

  render () {
    const buttonMinus = 'url' + '("../static/media/icons/minus.png")'
    const buttonPlus = 'url' + '("../static/media/icons/plus.png")'
    return (
      <div id='addInst' className='addInst' style={{ margin: '50px 0 50px 0' }}>
        {this.state.mode == 0 && (
          <div id='addInstButton'>
            <span>Add New Instrument</span>
            <span style={{ width: '50px', padding: '10px' }} />
            <Tooltip
              title='Press to open an instrument selection menu.'
              placement='bottom'
              id='addInstTT'
              enterDelay={1000}
            >
              <button
                onClick={this.add}
                style={{
                  background: 'none',
                  color: 'inherit',
                  padding: '0',
                  outline: 'inherit',
                  border: 'none'
                }}
              >
                <img
                  style={{ height: '20px', width: '20px' }}
                  src='./media/icons/plus.png'
                />
              </button>
            </Tooltip>
          </div>
        )}
        {this.state.mode == 1 && (
          <div id='addInstSel' className='instrumentOptions'>
            <span>Select Instrument</span>
            <span style={{ width: '50px', padding: '10px' }} />
            <button
              onClick={this.add}
              style={{
                background: 'none',
                color: 'inherit',
                padding: '0',
                outline: 'inherit',
                border: 'none'
              }}
            >
              <img
                style={{ height: '5px', width: '20px' }}
                src='./media/icons/minus.png'
              />
            </button>
            <br /> <br />
            <SelectTable
              instList={this.state.instList}
              addToSelection={this.addInstrument}
            />
          </div>
        )}
      </div>
    )
  }
}

const SelectTable = props => (
  <div>
    <SelectionRow
      instList={props.instList}
      addToSelection={props.addToSelection}
    />
  </div>
)

const SelectionRow = props => {
  if (typeof props.instList !== 'undefined') {
    return props.instList.map(inst => (
      <button
        id='buttonGlobal'
        key={inst.key}
        type='submit'
        value={inst.name}
        onClick={() => props.addToSelection({ key: inst.key, value: inst.id })}
      >
        {inst.name}
      </button>
    ))
  }
  return <div />
}
