import async from 'async';
import { injectGlobal } from 'emotion';
import { isNull, isNullOrUndefined, isUndefined } from 'util';
import WFDPlot from '../../ui/WaveformDataPlot';
import Timer from './Timer.jsx';

export default class Sequencer {
    constructor(props) {
        // each instrument allocated a sequencer. 
        // each sequencer handles calls to start preloading another page and playback of each sound file.
        this.id = props.id;
        this.pageStartTimes = props.pageStartTimes;
        this.pageDurs = props.pageDurs;
        this.pageDownStartTimes = props.pageDownStartTimes;
        this.SEstartPs = props.SEstartPs;
        this.pageIndex = 0;
        this.downPageIndex = 1;
        this.startTime;
        this.audioObjectArray = props.audioObjectArray;
        this.timerObjs = [];
        this.needle;
        this.preloadObjs = [];
        this.flipflop = 0;
        this.playingPageIndex = 0;
        this.isReady = { waveform: false, audio: false };
        this.isPlaying = false;
        this.gAWFD = new WFDPlot({

        });
        this.numPages = props.numPages;

        this.pageProgress = 0;
        this.inst = props.inst;
        this.pages = props.pages;

        this.startTime;
        this.playbackTimePassed;
        this.totalTimePassed;
        this.delay = 0;
        this.lastPauseTime;
        this.page = 0;
        this.soundPlayDelay = 0;
    }




    init = (ready, props) => {
        new Promise((resolve, reject) => {
            this.gAWFD.init({
                inst: props.inst,
                startPs: props.SEstartPs,
                SEdurs: props.SEdurs,
                pageDurs: this.pageDurs,
                numPages: props.numPages
            }, resolve, reject);
        }).then(() => {
            this.gAWFD.addToBuffers(props.inst, props.pages[0], 0, this.waveformLoaded, ready);
        }).catch(err => {
            console.log(err);
        });
    }

    waveformLoaded = (ready) => {
        this.isReady.waveform = true;
        if (!this.isPlaying && this.isReady.audio) {
            ready(this.inst);
            this.isPlaying = true;
        }
    }

    stop = (schedStopped, id, deleteSched, schedId) => {
        this.gAWFD.stop();
        this.timerObjs.map((timerObj) => {
            clearTimeout(timerObj);
        });


        this.preloadObjs.map((preloadObj) => {
            clearTimeout(preloadObj);
        });

        clearTimeout(this.needle);
        schedStopped(deleteSched, schedId, id);
    }

    prepareToPlay = (playSound, preload, loadingCallback, ready) => {
        // If second or third page needs to be downloaded, calculate this now:
        async.series([
            (callback) => {
                const pagesForPre = [];

                this.pageDownStartTimes.map((pDSTs, i) => {
                    if (pDSTs < 0) {
                        pagesForPre.push(i);
                    }
                });

                async.forEach(pagesForPre, (page, next) => {
                    preload(false, page, next);
                }, (err, results) => {
                    if (err) {
                        callback(err, null);
                    } else {
                        if (pagesForPre.length > 0) {
                            this.downPageIndex = pagesForPre.length - 1;
                        }
                        callback(null, 'preloaded pages');
                    }
                });
            },
            (callback) => {
                this.isReady.audio = true;
                // / start audio
                if (this.isReady.waveform && !this.isPlaying) {
                    this.isPlaying = true;
                    ready(this.inst);
                }
            }, (err, result) => {
                if (err) {
                    console.log(err);
                } else {
                    // console.log(result);
                }
            }]);
    }

    startPage = (pageIndex, _callback, playSound, pageProgress, finished) => {
        clearTimeout(this.needle);
        this.page = pageIndex;

        const timeToStartPage = this.startTime + this.delay + this.pageStartTimes[pageIndex + 1];
        // Start Timer for Next Page;
        this.preloadObjs[pageIndex] = setTimeout(() => {
            if (pageIndex < this.pageStartTimes.length - 1) {
                this.gAWFD.emptyBuffer(pageIndex);
                this.startPage(pageIndex + 1, () => {}, playSound, 0, finished);
            } else {
                _callback(false, true);
            }
        }, timeToStartPage - performance.now());

        if (pageIndex > this.numPages - 1) {
            _callback(null, 'Finished Peformance!');
            return;
        }
        this.gAWFD.show(pageIndex);
        this.playingPageIndex = pageIndex;

        const pageStartDelay = 0;

        if (typeof pageProgress == "undefined")
            this.playPage(pageIndex, playSound, 0, pageStartDelay);
        else
            this.playPage(pageIndex, playSound, pageProgress, pageStartDelay);
        this.flipflop = (this.flipflop * -1) + 1;

    }

    preloadPage = (callback, preload) => {
        if (!isNullOrUndefined(this.downPageIndex)) {
            if (this.downPageIndex < this.pages.length) {
                this.gAWFD.addToBuffers(this.inst, this.pages[this.downPageIndex], this.downPageIndex);
            } else {
                return;
            }
        } else {
            return;

        }

        var Promises = [];
        Promises[0] = new Promise((resolve, reject) => {
            this.preloadTimer = setTimeout(() => {
                if (parseInt(this.downPageIndex) < (this.pageStartTimes.length - 1)) {
                    this.preloadPage(resolve, preload);

                    callback(null, 'preloadTimeOutStarted');
                } else {
                    resolve(null, 'finished');
                }
            }, (this.pageDownStartTimes[this.downPageIndex] - this.pageDownStartTimes[this.downPageIndex - 1]));
        });

        Promises[1] = new Promise((resolve, reject) => {
            if (this.downPageIndex < this.pageStartTimes.length - 1) {
                const prevPage = this.downPageIndex;
                this.downPageIndex = parseInt(this.downPageIndex) + 1;

                // console.log
                preload(false, parseInt(prevPage), resolve); // this.timeTaken
            } else {
                resolve(null, 'finished!');
            }
        });

        Promise.all(Promises).then(results => {
            results.map(result => {
                if (typeof result !== 'string' || !isNaN(result || typeof result !== 'undefined')) {
                    if (results.id == 0) {
                        callback(null, 'finished!');
                        return;
                    }

                }
            });
            callback(null, "finished")
        });
    }

    pause = () => {

        this.lastPauseTime = performance.now();
        this.totalTimePassed = performance.now() - this.startTime;
        this.playbackTimePassed = performance.now() - this.startTime - this.delay;


        // calculate how far through a page we are. 
        this.timerObjs.map((timerObj) => {
            clearTimeout(timerObj);
        });


        this.preloadObjs.map((preloadObj) => {
            clearTimeout(preloadObj);
        });

        clearTimeout(this.needle);
        
    }

    resume = (playSound, preload, finished) => {
        this.delay += performance.now() - this.lastPauseTime;
        this.timePassedTotal = performance.now() - this.startTime;
        

        this.playFrom(this.playbackTimePassed, playSound, finished);

    }

    play = (loadingCallback, preload, playSound, finished) => {
        let Promises = [];
        Promises[0] = new Promise((resolve, reject) => {
            loadingCallback(false, '');
            this.preloadPage(resolve, preload); // first page is already downloaded.
        });

        Promises[1] = new Promise((resolve, reject) => {
            this.startTime = performance.now()
            resolve();
        });

        Promises[2] = new Promise((resolve, reject) => {
            resolve(this.startPage(0, () => {}, playSound,0, finished));
        });

        Promise.all(Promises).catch(err => {
            console.log(err);
        });
    }


    playFrom = (time, playSound, finished) => {
        var pageOffset = time - this.pageStartTimes[this.page];
        this.startPage(this.page, () => {
        }, playSound, pageOffset, finished);
    }


    resize = (width, height) => {
        if (typeof this.gAWFD !== 'undefined' || !isNull(this.gAWFD)) {
            this.gAWFD.resize(width, height, this.page);
        }
    }

    playLoop() {
        // play page.
        // play next page... continue.
    }

    playPage = (page, playSound, playOffset, pageStartDelay) => {
        // this.pageStartTime = (performance.now() + this.delay) - playFrom;
        
        
        // start tick
        this.needle = setInterval(() => this.tick(page, playOffset - pageStartDelay), 50);
        if(typeof this.SEstartPs[page] == "undefined") 
            return;

        const startTimes = this.SEstartPs[page].map((startP, i) => ({ i, p: startP * 1000 }));
        async.each(startTimes, (startTime, next) => {
            // if startTime is larger than play from, set a timer to play sound in the future.
            // or play the sound..
            // Play sound handles whether the delay will have passed the sound duration, or whether
            // to play from half way through.


            if (playOffset < startTime.p) {
                // var newTimer = //((startTime.p + performance.now()) - this.delay) - this.pageStartTime;
                const newTimer = startTime.p - playOffset;
                this.timerObjs[startTime.i] = setTimeout(() => {
                  //  console.log(this.soundPlayDelay);
                    playSound(page, startTime.i, 0);
                    //sync(startTime.i);
                    next();
                }, newTimer);
            } else {
                playSound(page, startTime.i, playOffset - startTime.p);
            }
        });
    }


    tick = (page) => {
        const timeNow = performance.now();
        const pageStartTime = this.startTime + this.delay + this.pageStartTimes[page];
        const pageEndTime = this.startTime + this.delay + this.pageStartTimes[page + 1];
        const percentage = (timeNow - pageStartTime)/(pageEndTime - pageStartTime);
        this.gAWFD.drawScrollArm(percentage);
        return 0;
    }
}
