import AudioObj from './AudioObj.jsx';

export default class AudioObjArray {
    constructor(urlArray, pageLoaded, page, pageId) {
        this.pageLoaded = pageLoaded.bind(this);
        this.page = page;
        this.pageId = pageId;
        this.urlArray = urlArray;
        this.bufferArray = [];
        this.loadedArray = [];
        this.audioObjs = [];
        if (typeof urlArray !== 'undefined') {
            urlArray.forEach((url, i) => {
                this.audioObjs.push(new AudioObj({ url, id: i, itemLoaded: this.itemLoaded }));
            });
        } else {

        }
    }

    itemLoaded = (id, buffer) => {
        this.loadedArray.push(id);
        this.bufferArray[id] = buffer;
        if (this.loadedArray.length == this.urlArray.length) {
            this.pageLoaded(null, { buffer: this.bufferArray, id: this.pageId, page: this.page });
        }
    }

    play(id, delay) {
        this.audioObjs[id].play(delay);
    }

    stop = (schedStopped, id, deleteSched, schedId, pause) => {
        this.audioObjs.map(audioObj => {
            if (typeof audioObj!== 'undefined') { audioObj.stop();
            if(!pause) {
                audioObj.unload;
            }}
        });

        schedStopped(deleteSched, schedId, id);
    }
}
