import React, { Component } from 'react'
import { withStyles } from '@mui/styles'
import { LinearProgress } from '@mui/material'

class ColoredLinearProgress extends Component {
  render () {
    const { classes } = this.props
    return (
      <LinearProgress
        {...this.props}
        classes={{
          colorPrimary: classes.colorPrimary,
          barColorPrimary: classes.barColorPrimary
        }}
      />
    )
  }
}

const styles = props => ({
  colorPrimary: {
    backgroundColor: '#c4c4c4'
  },
  barColorPrimary: {
    backgroundColor: '#000000'
  }
})

export default withStyles(styles)(ColoredLinearProgress)
