import React, { Component } from 'react'
import Item from './Item'
import Target from './Target.jsx'

import GetInstData from '../Data/getInstrumentData.jsx'

const data = [
  {
    key: 'bass',
    id: '0',
    name: 'Bass',
    Pages: '16'
  },
  {
    key: 'bassoon',
    id: '1',
    name: 'Sax & Bassoon',
    Pages: '12'
  },
  {
    key: 'cello',
    id: '2',
    name: 'Cello',
    Pages: '16'
  },
  {
    key: 'clarinet',
    id: '3',
    name: 'Clarinet',
    Pages: '12'
  },
  {
    key: 'flute',
    id: '4',
    name: 'Flute',
    Pages: '12'
  },
  {
    key: 'trombone',
    id: '5',
    name: 'Trombone',
    Pages: '12'
  },
  {
    key: 'trumpet',
    id: '6',
    name: 'Trumpet',
    Pages: '12'
  },
  {
    key: 'tuba',
    id: '7',
    name: 'Tuba',
    Pages: '12'
  },
  {
    key: 'viola 1',
    id: '8',
    name: 'Viola 1',
    Pages: '16'
  },
  {
    key: 'viola 2',
    id: '9',
    name: 'Viola 2',
    Pages: '16'
  },
  {
    key: 'violin 1',
    id: '10',
    name: 'Violin 1',
    Pages: '16'
  },
  {
    key: 'violin 2',
    id: '11',
    name: 'Violin 2',
    Pages: '16'
  },
  {
    key: 'violin 3',
    id: '12',
    name: 'Violin 3',
    Pages: '16'
  },
  {
    key: 'piano',
    id: '13',
    name: 'Piano',
    Pages: '16'
  }
]

export default class Instrument extends Component {
  _isMounted = false

  constructor (props) {
    super()
    this.checkSizes = false
    this.targetRef = React.createRef()
    this.windowWidth = 0

    this.lastAddedLength = 0
    this.autoSelection = []
    if (typeof props.autoSelection.sel !== 'undefined') {
      this.autoSelection = props.autoSelection.sel.pages
    }
    this.opacityTog = 0
    this.newItemAdded = false
  }

  state = {
    itemsOrder: [],
    items: [
      {
        id: 0,
        name: 'silence',
        minDur: 1,
        artist: '',
        info: ''
      }
    ],
    addedItems: [],
    activeItemIndex: 0,
    gminDurs: [],
    perform: true,
    name: '',
    key: '',
    playMode: false,
    maxMinDur: 0,
    instMinDur: 0,
    artist: '',
    info: '',
    editable: false,
    loading: true,
    targetWidth: window.innerWidth - 50,
    itemSlideNo: 0
  }

  setAutoSelections = sel => {
    if (typeof sel !== 'undefined') {
      if (typeof sel[0] !== 'undefined') {
        if (typeof sel[0].minDur == 'undefined') {
          this.setState({
            addedItems: sel[0]
          })
        } else {
          this.setState({
            addedItems: sel
          })
        }
      } else {
        this.setState({
          addedItems: sel
        })
      }
    }
  }

  removeItem = (id, callback) => {
    // removeAddedElement
    let removed

    this.setState(prevState => {
      removed = prevState.addedItems.splice(id, 1)[0]
      if (removed.id == 0) {
      } else {
        const rebuiltItem = {
          id: removed.id,
          name: `page ${removed.id}`,
          minDur: this.state.gminDurs.pages[removed.id - 1]
        }
        const remainingIds = prevState.items.map(item => item.id)
        let insertIndex = -1
        if (remainingIds.length == 1) {
          if (remainingIds[0] > removed.id) {
            // push in front.
            prevState.items.unshift(rebuiltItem)
          } else {
            prevState.items.push(rebuiltItem)
          }
        } else {
          for (const i in remainingIds) {
            if (remainingIds[i] > removed.id) {
              insertIndex = i
              break
            }
          }
          if (insertIndex == -1) {
            prevState.items.push(rebuiltItem)
          } else {
            prevState.items.splice(insertIndex, 0, rebuiltItem)
          }
        }
        prevState.items.push()
      }

      if (typeof this.props.itemToRemove !== 'undefined') {
        this.props.lastItemRemoved()
      }
      return prevState
    })
  }

  UNSAFE_componentWillMount () {}

  shouldComponentUpdate (nextProps, nextState) {
    return true
  }

  selsAdded = () => {
    this.setState({ state: this.state })
  }

  UNSAFE_componentWillReceiveProps (props) {
    if (this.state.targetWidth != props.windoWidth - 50) {
      this.setState({ targetWidth: props.windowWidth - 50 })
    }

    if (!props.playMode) {
      if (props.turnOffEditing) {
        this.setState({ editable: false })
      }
    }
  }

  init = () => {
    const getData = new GetInstData()
    const that = this
    this.windowWidth = window.innerWidth
    new Promise((resolve, reject) => {
      getData.getMinimumDurations(that.props.selection, 0.5, resolve)
    }).then(minDurs => {
      new Promise((resolve, reject) => {
        that.setState({ maxMinDur: minDurs.max })
        let durSum = 0
        for (const i in minDurs.pages) {
          durSum += minDurs.pages[i]
        }

        this.setState({ instMinDur: durSum, gminDurs: minDurs })

        that.populate(that.props.selection, minDurs.pages, resolve)
      }).then(prevData => {
        new Promise((resolve, reject) => {
          getData.getInstrumentalistInfo(that.props.selection, resolve)
        })
          .then(result => {
            that.setState({ artist: '', info: result.info, loading: false })
            this.setAutoSelections(this.autoSelection)
          })
          .catch(err => {
            this.setState({ loading: false })
            console.log(err)
          })
      })
    })
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  componentDidMount () {
    this._isMounted = true
    this.init()
  }

  componentDidUpdate () {
    if (this.opacityTog == 0) {
      this.opacityTog = 1
    }
    if (
      this.state.addedItems.length > 0 &&
      this.lastAddedLength != this.state.addedItems.length
    ) {
      this.lastAddedLength = this.state.addedItems.length
      this.props.pageSelections({
        key: this.state.key,
        value: this.state.addedItems
      })
    }
  }

  windowResize = () => {
    this.windowWidth = window.innerWidth
  }

  populate = (selection, minDurs, callback) => {
    const name = ''
    this.setState(prevState => {
      data.find(instrument => {
        if (instrument.key == selection) {
          prevState.name = instrument.name
          prevState.key = instrument.key
          for (let i = 0; i < instrument.Pages; i++) {
            prevState.items.push({
              id: i + 1,
              name: `page ${i + 1}`,
              minDur: minDurs[i]
            })
          }
        }
      })
    })
    callback()
  }

  deleteItem = addedItem => {
    this.newItemAdded = true
    this.checkSizes = true
    let newAdd

    this.setState(
      prevState => {
        const { items } = prevState
        const { addedItems } = prevState

        const index = items.findIndex(item => item.id === addedItem.id)
        if (addedItem.id === 0) {
          newAdd = items
        } else {
          newAdd = items.splice(index, 1)
        }

        addedItems.push({
          id: newAdd[0].id,
          name: newAdd[0].name,
          minDur: newAdd[0].minDur
        })
        prevState.items = items
        prevState.addedItems = addedItems

        return { prevState }
      },
      () => {
        // this.forceUpdate();
      }
    )
  }

  checkItemList = () => {
    this.setState(prevState => {
      var items = prevState.items
      const { addedItems } = prevState

      addedItems.map(addedItem => {
        const index = items.findIndex(item => {
          return item.id === addedItem.id
        })

        if (index > 0) {
          items.splice(index, 1)
        }
      })

      prevState.items = items
      return prevState
    })
  }

  undo = () => {
    this.setState(prevState => {
      const { addedItems } = prevState
      addedItems.slice(prevState.addedItems.length - 1)
      return addedItems
    })
  }

  perform = e => {
    e.preventDefault()

    if (this.state.perform == false) {
      this.state.perform = true
    } else {
      this.state.perform = false
    }

    this.forceUpdate()
  }

  resetCheckSizes = () => {
    this.checkSizes = false
  }

  getSelections = (addedItems, inst, boxWidths) => {
    this.props.getSelections(
      addedItems,
      inst,
      boxWidths.map(boxWidth => boxWidth / this.props.timeUnit)
    )
  }

  removeInst = () => {
    this.props.removeInst(this.props.selection)
  }

  changeActiveItem = activeItemIndex => this.setState({ activeItemIndex })

  chevron = (dir, upperLim) => {
    if (dir == 0) {
      if (this.state.itemSlideNo <= 0) {
        this.setState(prevState => {
          prevState.itemSlideNo = 0
          return prevState
        })
      } else {
        this.setState(prevState => {
          var no = prevState.itemSlideNo
          prevState.itemSlideNo = no - 1
          return prevState
        })
      }
    } else if (this.state.itemSlideNo >= upperLim - 1) {
      this.state.itemSlideNo = upperLim - 1
    } else {
      this.setState(prevState => {
        var no = prevState.itemSlideNo
        prevState.itemSlideNo = no + 1
        return prevState
      })
    }
    // this.forceUpdate();
  }

  toggleEditState = () => {
    this.checkItemList()
    this.setState(prevState => {
      prevState.editable = !prevState.editable
      return prevState
    })
    this.props.instBeingEdited(this.props.selection)
  }

  rearranged = addedItems => {
    this.setState({ addedItems: addedItems })
  }

  render () {
    const settings = {}

    let showChevrons = false

    const itemListWidth = this.state.items.length * 75
    let itemLimit = 17
    const gapSize = this.windowWidth - 150
    let itemSlides = 0

    if (itemListWidth > gapSize) {
      showChevrons = true
      itemLimit = Math.floor(gapSize / 75)
      itemSlides = Math.ceil(this.state.items.length / itemLimit)
    } else {
      this.state.itemSlideNo = 0
    }

    const items = this.state.items.map((item, index) => (
      <div
        style={{ width: '75px', height: '75px', display: 'inline-block' }}
        key={index}
      >
        <Item
          key={item.id}
          item={item}
          handleDrop={id => this.deleteItem(item)}
          style={{
            borderLeftColor: '#d6d6d6',
            borderRightColor: '#d6d6d6',
            borderLeftWidth: '2px',
            borderRightWidth: '2px',
            display: 'inline-block'
          }}
        />
      </div>
    ))

    const renderCheck = true //= (!this.state.loading && typeof this.state.gminDurs.pages !== 'undefined') || this.state.;
    // <button style={{ display: 'inline-block' }} value="perform" id="buttonGlobal" onClick={this.perform}>Add Pages</button>
    return (
      <div
        style={{ opacity: this.opacityTog, height: '100%' }}
        className={`${this.props.selection}_track`}
        id='fade-in'
      >
        <div className='app-container' style={{ height: '100%' }}>
          <div className='instHead' id='fade-in-long' style={{}}>
            <h3 style={{ opacity: 'inherit', float: 'left' }}>
              {this.state.name}
            </h3>
            <h4
              style={{
                opacity: 'inherit',
                float: 'left',
                margin: '9px 0 0 5px'
              }}
            >
              {' '}
            </h4>

            {!this.props.playMode && (
              <div style={{ opacity: 'inherit', float: 'right' }}>
                <button
                  style={{ opacity: 'inherit', display: 'inline-block' }}
                  id='buttonGlobal'
                  onClick={this.toggleEditState}
                >
                  Edit
                </button>
                <button
                  style={{ opacity: 'inherit', display: 'inline-block' }}
                  value='perform'
                  id='buttonGlobal'
                  onClick={this.removeInst}
                >
                  Remove
                </button>
                {this.state.editable && <div></div>}
              </div>
            )}
          </div>
          {(this.state.editable != true && this.state.perform == true) ||
          this.props.playMode ? (
            <div />
          ) : (
            <div className='item-container'>
              {itemSlides > 0 && (
                <button
                  id='img-button'
                  onClick={() => {
                    this.chevron(0, itemSlides)
                  }}
                  className='itemRight'
                  style={{ float: 'left' }}
                >
                  <img
                    id='button-img'
                    style={{ float: 'left', width: '25px' }}
                    src='./media/icons/left_chevron.png'
                  />
                </button>
              )}
              {items.slice(
                itemLimit * this.state.itemSlideNo,
                itemLimit * (this.state.itemSlideNo + 1)
              )}
              {itemSlides > 0 && (
                <button
                  id='img-button'
                  onClick={() => {
                    this.chevron(1, itemSlides)
                  }}
                  className='itemRight'
                  style={{ float: 'right' }}
                >
                  <img
                    id='button-img'
                    style={{ float: 'right', width: '25px' }}
                    src='./media/icons/right_chevron.png'
                  />
                </button>
              )}
            </div>
          )}

          {renderCheck ? (
            <Target
              ref={this.targetRef}
              id={`${this.props.selection}_target`}
              targetWidth={window.innerWidth - 50}
              instrument={this.props.selection}
              checkSizeOf={this.checkSizes}
              resetCheckSizes={this.resetCheckSizes}
              addedItems={this.state.addedItems}
              boxHeight={this.props.boxHeight}
              play={this.props.play}
              playMode={this.props.playMode}
              removeItem={this.removeItem}
              passSelections={this.props.passSelections}
              getSelections={this.getSelections}
              timeUnit={this.props.timeUnit}
              minDurs={this.state.gminDurs}
              durationSet={this.props.durationSet}
              undo={this.undo}
              showDurErr={this.props.showDurErr}
              editable={this.state.editable}
              autoSelection={this.autoSelection}
              autoSelsAdded={this.props.autoSelsAdded}
              removeAutoSel={this.props.removeAutoSel}
              selsAdded={this.selsAdded}
              itemToRemove={this.props.itemToRemove}
              itemsToAdd={this.state.addedItems}
              durErr={this.props.durErr}
              rearranged={this.rearranged}
              windowWidth={this.props.windowWidth}
              zoomed={this.props.zoomed}
              zoomAccepted={this.props.zoomAccepted}
              randomSizes={this.props.randomSizes}
            />
          ) : (
            <div />
          )}
        </div>
      </div>
    )
  }
}
