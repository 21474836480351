export const serverAddress = window['serverAddress']
export const resLocation = serverAddress && serverAddress + './../static/res'
export const fileTimingsJSON = resLocation + '/info/fileTimings.json'
export const pageDursJSON = resLocation + '/info/pageDurs.json'
export const instrumentalistsJSON = resLocation + '/info/instrumentalists.json'
export const instrumentPagesJSON = resLocation + '/info/instrumentPages.json'
export const durationsJSON = ''
export const bassJSON = resLocation + '/info/urls/Bass/Bass.json'
export const bassoonJSON = resLocation + '/info/urls/Bassoon/Bassoon.json'
export const celloJSON = resLocation + '/info/urls/Cello/Cello.json'
export const clarinetJSON = resLocation + '/info/urls/Clarinet/Clarinet.json'
export const fluteJSON = resLocation + '/info/urls/Flute/Flute.json'
export const tromboneJSON = resLocation + '/info/urls/Trombone/Trombone.json'
export const trumpetJSON = resLocation + '/info/urls/Trumpet/Trumpet.json'
export const tubaJSON = resLocation + '/info/urls/Tuba/Tuba.json'
export const pianoJSON = resLocation + '/info/urls/Piano/Piano.json'
export const viola1JSON = resLocation + '/info/urls/Viola1/Viola1.json'
export const viola2JSON = resLocation + '/info/urls/Viola2/Viola2.json'
export const violin1JSON = resLocation + '/info/urls/Violin1/Violin1.json'
export const violin2JSON = resLocation + '/info/urls/Violin2/Violin2.json'
export const violin3JSON = resLocation + '/info/urls/Violin3/Violin3.json'
export const pageSizesJSON = resLocation + '/info/PageFileSizes.json'
export const waveformFilesLoc = '../../static/res/WaveformData'
