import { DragSource, DropTarget } from 'react-dnd';
import { Component } from 'react';

const itemSource = {
    beginDrag(props) {
        return props.item;
    },
    endDrag(props, monitor, component) {
        if (!monitor.didDrop()) {
            return;
        }

        return props.handleDrop(props.id);
    }
};

const itemTarget = {
    hover(props, monitor, component) {
        if (!component) {
            return null;
        }
        const hoverIndex = props.index;
        const dragIndex = monitor.getItem().index;
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }
        // Determine rectangle on screen
        // const hoverBoundingRect = findDOMNode(component).getBoundingClientRect()
        // // Get horizontal middle
        // const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) * 0.95 
        // // Determine mouse position
        // const clientOffset = monitor.getClientOffset()
        // // Get pixels to the top
        // const hoverClientX = clientOffset.x - hoverBoundingRect.left
        // // Only perform the move when the mouse has crossed half of the items height
        // // When dragging downwards, only move when the cursor is below 50%
        // // When dragging upwards, only move when the cursor is above 50%
        // // Dragging downwards
        // if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        //     //console.log("dragging right");
        //     return
        // }
        // // Dragging upwards
        // if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        //     //console.log("dragging left");
        //     return
        // }
        // Time to actually perform the action
        props.rearrange(dragIndex, hoverIndex, props.id);
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    }
};


function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging(),
    };
}


class ItemDragBox extends Component {
    constructor(props) {
        super();
        this.width = props.width;
        this.height = props.height;
    }

    state = {
        hover: false,
        deleteHovered: false
    }

    hovering = () => {
        this.setState({ hover: true });
    }

    notHovering = () => {
        this.setState({ hover: false });
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.width = newProps.width;
        this.height = newProps.height;
    }

    deleteHover = (bool) => {
        if(bool) {
            this.setState({deleteHovered: true});
        } else {
            this.setState({deleteHovered: false})
        }
    }

    render() {
        const {
 isDragging, connectDragSource, connectDropTarget, item 
} = this.props;
        // var background = this.props.name == "silence" ? "#ffffff" : "#f0f0f0";
        var background = this.props.atMinWidth ? '#d9d9d9' : 'rgb(240, 240, 240)';
        background = this.props.dragHover ? "#000000" : "#f0f0f0";
        if(this.props.item.name == "silence") {
            background = "rgba(255,255,255,0.5)";
        }
        const marginLeft = this.props.dragHover ? '5px' : 'auto';
        const width = 'calc(100% - 5px)';
        const height = '99%';
        const style = {
            position: 'relative',
            width,
            height,
            margin: 'auto',
            border: 'solid 1px #c4c4c4',
            background,
            marginLeft,
            MozUserSelect: 'none',
            WebkitUserSelect: 'none',
            msUserSelect: 'none',
            borderBottomColor: '#888',
        };

        let val;
        if (!Number.isInteger(parseInt(this.props.item.name[this.props.item.name.length - 2]))) {
            val = this.props.item.name[this.props.item.name.length - 1];
        } else {
            val = this.props.item.name[this.props.item.name.length - 2] + this.props.item.name[this.props.item.name.length - 1];
        }
        
        if (!Number.isInteger(parseInt(val))) {
            val = 's';
        }

        var deleteBackground = this.state.deleteHovered ? '#b5bec7' : 'none';

        return connectDragSource(connectDropTarget(
          <div className="item2" onMouseEnter={this.hovering} onMouseLeave={this.notHovering} style={style}>
            <span
              id="itemText"
              style={{
margin: '40px auto auto auto', fontSize: '35px', textAlign: 'center', lineHeight: height, verticalAlign: 'middle' 
}}
            >
              { val }
            </span>
            <button
              id="itemText"
              onClick={this.props.removeMe}
              onMouseEnter={() => {
                  this.deleteHover(true);
              }}
              onMouseLeave={() => {
                this.deleteHover(false);
            }}
              style={{
 position: 'absolute', left: 0, bottom: 0, width: '100%', background: deleteBackground, color: 'inherit', padding: '0', outline: 'inherit', border: 'none' 
}}
            >
              <img
                style={{
                    display: 'block', 
                    height: '15px', 
                    width: '15px', 
                    margin: 'auto auto 10px auto',
                    paddingTop: '5px'
}}
                src="../media/icons/cross.png"
              />
            </button>
          </div>
        ));
    }
}


export default DropTarget(['item2', 'item'], itemTarget, (connect) => ({
    connectDropTarget: connect.dropTarget(),
}))(DragSource('item2', itemSource, collect)(ItemDragBox));
