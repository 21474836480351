import { axiosGet } from './GetFromServer'
import GetInstData from './getInstrumentData.jsx'
import { pageDursJSON } from '../appContext'

const instNames = [
  'violin3',
  'trombone',
  'violin2',
  'violin1',
  'cello',
  'flute',
  'bass',
  'trumpet',
  'tuba',
  'viola2',
  'viola1',
  'bassoon',
  'clarinet',
  'piano'
]

export default class GenSelections {
  constructor () {
    this.pageDurations
    this.ready = false
    this.showDurErr
  }

  init () {
    const retries = 0
    // this.connectToResources(retries);
  }

  connectToResources = retries => {
    if (retries < 10) {
      new Promise((_resolve, reject) => {
        axiosGet(pageDursJSON, serverPort, _resolve, reject, retries)
      })
        .then(response => {
          this.pageDurations = response.data.pagesDurs
          this.ready = true

          // super.componentReady("genSel");
          return 0
        })
        .catch(error => {
          console.log(error)
          // this.connectToResources(retries++);
        })
    } else {
      console.log(
        'Failed to connect to resources!\nThere is an issue with the server!'
      )
      super.componentFailed('genSel')
      return -1
    }
  }

  generate = (
    instN,
    _pageN,
    dur,
    incPiano,
    pianoDurErr,
    addInstruments,
    randomPageSizes,
    incSilences,
    __resolve
  ) => {
    const getData = new GetInstData()
    const sels = []

    new Promise((____resolve, reject) => {
      if (incPiano) {
        const availablePianoPages = []
        new Promise((_resolve, reject) => {
          getData.getMinimumDurations('piano', 0, _resolve)
        }).then(pianoMinDurs => {
          const pianoDurs = pianoMinDurs.pages // this.pageDurations[instNames.length-1].pageDurs;
          let min = 1000000000
          for (const i in pianoDurs) {
            if (pianoDurs[i] < min) {
              min = pianoDurs[i]
            }

            if (dur >= pianoDurs[i]) {
              availablePianoPages.push(i)
            }
          }

          if (availablePianoPages.length == 0) {
            pianoDurErr(min)
            return
          }
          let pianoPageTotalDur = 0
          const pianoPages = []
          while (availablePianoPages.length > 0) {
            const pageSel = Math.floor(
              Math.random() * availablePianoPages.length
            )
            const newPPTotalDur =
              pianoPageTotalDur + pianoDurs[availablePianoPages[pageSel]]

            if (newPPTotalDur <= dur) {
              pianoPageTotalDur = newPPTotalDur
              const id = Number(availablePianoPages[pageSel]) + 1
              pianoPages.push({
                id,
                name: `Page ${id}`,
                minDur: pianoDurs[availablePianoPages[pageSel]]
              })
              availablePianoPages.splice(pageSel, 1)
            } else {
              availablePianoPages.splice(pageSel, 1)
            }
          }

          sels.push({ inst: 'piano', instId: 13, pages: pianoPages })
          ____resolve()
        })
      } else {
        ____resolve()
      }
    })
      .then(() => {
        if (instN == -1) {
          instN = Math.floor(Math.random() * 13)
        }

        const insts = this.fillRange(0, instNames.length - 2)
        const Promises = []
        const instSels = []

        for (var i = 0; i < instN; i++) {
          const sel = Math.floor(Math.random() * insts.length)
          instSels.push(insts[sel])
          insts.splice(sel, 1)
          Promises.push(
            new Promise((___resolve, reject) => {
              getData.getMinimumDurations(
                instNames[instSels[i]],
                0.5,
                ___resolve
              )
            })
          )
        }

        Promise.all(Promises)
          .then(allPageDurations => {
            for (const i in allPageDurations) {
              const pagesDurs = allPageDurations[i].pages
              const availablePages = pagesDurs.length
              let pagesToUse = 0

              var pageN
              if (_pageN == 0) {
                pageN = Math.floor(Math.random() * 15 + 1)
              } else {
                pageN = _pageN
              }

              if (availablePages < pageN) {
                pagesToUse = availablePages
              } else {
                pagesToUse = pageN
              }

              let pagesLeft = this.fillRange(0, availablePages - 1)
              const pages = []
              let minimumLength = 0
              var addedDur = 0

              for (let j = 0; j < pagesToUse; j++) {
                const pSel = Math.floor(Math.random() * pagesLeft.length)
                const pageMinDur = pagesDurs[pagesLeft[pSel]]
                if (minimumLength + pageMinDur > dur) {
                  pagesLeft = []
                } else {
                  if (typeof pagesLeft[pSel] === 'undefined') {
                    break
                  }

                  var incSilence = 0
                  if (incSilences) {
                    incSilence = Math.random()
                  }

                  if (incSilence > 0.8) {
                    pages.push({ id: 0, name: 'silence', minDur: 0, genDur: 0 })
                  } else {
                    const id = pagesLeft[pSel] + 1
                    pages.push({
                      id,
                      name: `page ${id}`,
                      minDur: pageMinDur,
                      genDur: 0
                    })
                    pagesLeft.splice(pSel, 1)
                  }

                  addedDur += pageMinDur
                  minimumLength += pageMinDur
                }
              }

              if (randomPageSizes) {
                var divs = []
                var randTotal = 0
                for (var f = 0; f < pages.length; f++) {
                  divs[f] = Math.random()
                  randTotal = divs[f]
                }

                divs.map(div => {
                  div /= randTotal
                  div *= dur - addedDur
                })

                pages.map((page, f) => {
                  page.genDur = ((dur - addedDur) * divs[f]) / randTotal
                })
              }

              const instName = instNames[instSels[i]]

              sels.push({ inst: instName, instId: instSels[i], pages: [pages] })
            }

            addInstruments(sels)
            __resolve(
              sels.map(sel => {
                return sel.inst
              })
            )
          })
          .catch(err => {
            console.log(err)
          })
      })
      .catch(err => {
        console.log(err)
      })
  }

  //

  fillRange = (start, end) =>
    Array(end - start + 1)
      .fill()
      .map((item, index) => start + index)

  sum = durs => {
    let minDur = 0
    for (const i in durs) {
      minDur += durs[i]
    }
    return minDur
  }
}
