
// MaxMinDur = the length of the sound events in sequence.

export function Run(stPs, durs, maxMinDur, selPageDur, step, inst) {
    selPageDur = Number(`${Math.ceil(`${selPageDur}e2`)}e-2`) + 0.1;
    stPs.push(1);
    durs.push(0);
    const N = stPs.length;
    if (N != durs.length) {
        console.log('Error: Internal problem with server files, contact the website host to resolve the issue!');
        console.log(`Instrument: ${inst}`);
        console.log(`N of Start Positions: ${N}`);
        console.log(`N of Durations: ${durs.length}`);
        // Callback("Error: Start Positions or File Durations has not downloaded correctly, suggesting error with server!", null);
        return null;
    }

    if (selPageDur < maxMinDur) {
        // Callback("Error: selected duration is invalid, please select a higher duration!", null);
        console.log('Error: selected duration is invalid, please select a higher duration!');
        console.log(`Selected Page Duration: ${selPageDur}`);
        console.log(`Minimum Duration of Page: ${maxMinDur}`);
        return null;
    }

    let minDurRatio = 0;

    // Calculate minimum duration using "ax + b = cx";
    for (var i = 0; i < N - 1; i++) {
        const x = -durs[i] / (stPs[i] - stPs[i + 1]);
        if (x > minDurRatio) {
            minDurRatio = x;
        }
    }


    const maxMinDurSPs = new Array(N);

    // Get start positions for maximum minimum-duration.
    for (var i = 0; i < N; i++) {
        maxMinDurSPs[i] = stPs[i] * minDurRatio;
    }

    const whiteSpace = new Array(N);

    whiteSpace[0] = maxMinDurSPs[0];

    let whiteSpaceSum = whiteSpace[0];

    /*
     * Calculate the white spaces between each of the sound events
     * and sum.
     */


    for (var i = 1; i < N; i++) {
        whiteSpace[i] = maxMinDurSPs[i] - (maxMinDurSPs[i - 1] + durs[i - 1]);
        whiteSpaceSum += whiteSpace[i];
    }

    /*
     * Calculate whitespace of desired duration;
     * desired duration should be controlled and above the m
     */
    const desiredDurWS = selPageDur - maxMinDur;

        const whiteSpaceRatio = desiredDurWS / whiteSpaceSum;

    // Re-use whitespace to calculate new whitespace.
    for (var i in whiteSpace) {
        whiteSpace[i] *= whiteSpaceRatio;
    }

    const alteredStartPs = new Array(N);

    alteredStartPs[0] = whiteSpace[0];

    for (var i = 1; i < N; i++) {
        alteredStartPs[i] = alteredStartPs[i - 1] + whiteSpace[i] + durs[i - 1];
    }

    step(
        null,
        {
selPageDur,
            startPs: alteredStartPs.slice(
                0,
                N - 1
            ),
            durs 
}
    );
}
