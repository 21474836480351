import Help from './Help.jsx'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'

export default class EnterDuration extends React.Component {
  constructor (props) {
    super()
    this.H = 0
    this.M = 0
    this.S = 0
  }

  state = {
    numbersEntered: false,
    hide: false,
    firstOpen: true,
    open: false,
    noDurOpen: false
  }

  setDuration = e => {
    e.preventDefault()

    if (typeof this.M === 'undefined' || isNaN(this.M)) {
      this.M = 0
    }
    if (typeof this.S === 'undefined' || isNaN(this.S)) {
      this.S = 0
    }
    const durInS = this.H * 3600 + this.M * 60 + this.S
    if (durInS == 'undefined' || isNaN(durInS) || durInS == 0) {
      this.setState({ noDurOpen: true })
      return
    }
    this.props.setDuration(durInS)
    // document.getElementById("overlay").style.display = "none";
    if (this.state.firstOpen) {
      this.props.openGenPage()
      this.setState({ firstOpen: false })
    }
    this.setState({ hide: true })
    this.props.acceptDuration()
  }

  enteredNumbers = e => {
    if (e.value.length == 6) {
      this.setState({ numbersEntered: true })
    } else {
      this.setState({ numbersEntered: false })
    }
  }

  onChange = e => {
    if (e.target.className == 'durH') {
      this.H = parseInt(e.target.value)
    } else if (e.target.className == 'durM') {
      this.M = parseInt(e.target.value)
    } else if (e.target.className == 'durS') {
      this.S = parseInt(e.target.value)
    }
  }

  handleClickOpen = () => {
    this.setState({ noDurOpen: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  maxLengthCheck = object => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      )
    }
    if (object.target.value > 59) {
      object.target.value = 59
    }

    if (object.target.value < 0) {
      object.target.value = 0
    }
  }

  handleClose = () => {
    this.setState({ noDurOpen: false })
  }

  render () {
    let display = 'none'
    if (this.props.changeDuration || !this.state.hide) {
      display = 'inline'
    }

    return (
      <div className='initDuration' id='overlay' style={{ display }}>
        <form
          style={{
            position: 'absolute',
            width: '530px',
            height: '250px',
            top: '50%',
            left: '50%',
            margin: '-125px 0 0 -265px'
          }}
          onSubmit={this.setDuration}
        >
          Enter Duration
          <br />
          H:
          <input
            className='durH'
            id='durationBox'
            type='number'
            maxLength='2'
            onInput={this.maxLengthCheck}
            onChange={this.onChange}
            min={0}
            defaultValue={0}
            autoComplete='off'
            style={{
              width: '60px',
              borderRadius: '10px 10px',
              margin: '20px 20px 0 0',
              padding: 'auto',
              fontSize: '25px',
              fontFamily: 'Merriweather',
              textAlign: 'center'
            }}
          />
          M:
          <input
            className='durM'
            id='durationBox'
            type='number'
            maxLength='2'
            onInput={this.maxLengthCheck}
            onChange={this.onChange}
            min={0}
            autoComplete='off'
            defaultValue={0}
            style={{
              width: '60px',
              borderRadius: '10px 10px',
              margin: '20px 20px 0 0',
              padding: 'auto',
              fontSize: '25px',
              fontFamily: 'Merriweather',
              textAlign: 'center'
            }}
          />
          S:
          <input
            className='durS'
            id='durationBox'
            type='number'
            maxLength='2'
            onInput={this.maxLengthCheck}
            onChange={this.onChange}
            min={0}
            defaultValue={0}
            autoComplete='off'
            style={{
              width: '60px',
              borderRadius: '10px 10px',
              margin: '20px 20px 0 0',
              padding: 'auto',
              fontSize: '25px',
              fontFamily: 'Merriweather',
              textAlign: 'center'
            }}
          />
          <br />
          <button
            id='buttonGlobal'
            onClick={this.props.showIntro}
            style={{
              marginTop: '35px'
            }}
          >
            Back
          </button>
          <input
            id='buttonGlobal'
            type='submit'
            value='Accept'
            style={{
              marginTop: '35px'
            }}
          />
        </form>
        <Help page={0} />

        <Dialog
          open={this.state.noDurOpen}
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            No Duration Entered!
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              <p>A Duration must be entered to continue!</p>
              <p>Try 30 minutes.</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button onClick={this.handleClose}>Okay</button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
