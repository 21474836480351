import async from 'async'
// import axios from 'axios'
import { isNull } from 'util'
import Sequencer from './Sequencer'
import AudioObjArray from '../Audio/AudioObjArray.jsx'
// import {
//   bassJSON,
//   bassoonJSON,
//   celloJSON,
//   clarinetJSON,
//   fluteJSON,
//   pianoJSON,
//   tromboneJSON,
//   trumpetJSON,
//   tubaJSON,
//   viola1JSON,
//   viola2JSON,
//   violin1JSON,
//   violin2JSON,
//   violin3JSON,
//   pageSizesJSON
// } from '../../appContext'

import bassJson from '../../../static/res/info/urls/Bass/Bass.json'
import bassoonJson from '../../../static/res/info/urls/Bassoon/Bassoon.json'
import celloJson from '../../../static/res/info/urls/Cello/Cello.json'
import clarinetJson from '../../../static/res/info/urls/Clarinet/Clarinet.json'
import fluteJson from '../../../static/res/info/urls/Flute/Flute.json'
import pianoJson from '../../../static/res/info/urls/Piano/Piano.json'
import tromboneJson from '../../../static/res/info/urls/Trombone/Trombone.json'
import trumpetJson from '../../../static/res/info/urls/Trumpet/Trumpet.json'
import tubaJson from '../../../static/res/info/urls/Tuba/Tuba.json'
import viola1Json from '../../../static/res/info/urls/Viola1/Viola1.json'
import viola2Json from '../../../static/res/info/urls/Viola2/Viola2.json'
import violin1Json from '../../../static/res/info/urls/Violin1/Violin1.json'
import violin2Json from '../../../static/res/info/urls/Violin2/Violin2.json'
import violin3Json from '../../../static/res/info/urls/Violin3/Violin3.json'
import pageSizesJson from '../../../static/res/info/PageFileSizes.json'


export default class SchedulerGlobal {
  constructor (schedulerData, instrument) {
    this.pageAudioArray = []
    this.instrument = schedulerData[0].inst
    this.pages = schedulerData.map(page => page.page)

    this.pageDurs = schedulerData.map(page => page.data.selPageDur)
    this.startPs = schedulerData.map(page => page.data.startPs)

    this.durs = schedulerData.map(page => page.data.durs)
    this.fileUrls = []

    this.pagesLoadedN = []
    this.pageStartTimes = this.calculatePageStartPoints()
    this.seq
    this.relativeDownStartTimes
    this.firstPageWaveform
    this.seqStopped = false
    this.playPageArrayStopped = false
  }

  resize = (width, height) => {
    if (typeof this.seq != 'undefined') {
      this.seq.resize(width, height)
    }
  }

  componentDidMount () {}

  pause = () => {
    for (const i in this.pageAudioArray) {
      this.pageAudioArray[i].stop(() => {})
    }
    this.seq.pause()
  }

  resume = finished => {
    this.seq.resume(this.playSound, this.preload, finished)
  }

  windowResize = () => {
    if (typeof this.seq !== 'undefined' || isNull(this.seq)) {
      this.seq.windowResize()
    }
  }

  stop = (deleteSched, schedId) => {
    for (const i in this.pageAudioArray) {
      this.pageAudioArray[i].stop(this.stopped, 0, deleteSched, schedId)
    }
    if (typeof this.seq !== 'undefined') {
      this.seq.stop(this.stopped, 1, deleteSched, schedId)
    }
  }

  stopped = (deleteSched, schedId, id) => {
    if (id == 0) {
      this.playPageArrayStopped = true
    } else {
      this.seqStopped = true
    }
    if (this.playPageArrayStopped && this.seqStopped) {
      deleteSched(schedId)
    }
  }

  run = (loading, appCallback) => {
    const that = this
    async.waterfall(
      [
        callback => {
          // this.getApi(callback, pageSizesJSON)
          callback(null, pageSizesJson)
        },
        (pageSizes, callback) => {
          this.pageSizes = pageSizes

          loading(true, 'Locating Audio Files')
          this.getUrls(callback, this.pages, pageSizes)
        },
        (urls, callback) => {
          this.fileUrls = urls

          loading(true, 'Downloading First Pages')
          // urls checksum;
          if (typeof urls === 'undefined') {
            callback(
              'Locating of files has failed. Please retry playing the page.',
              null
            )
          }

          that.init(callback)
        },
        (result, callback) => {
          loading(true, 'Loading Waveforms')
          this.calculateProjectedDownloadTime(result.timeTaken, callback)
        }
      ],
      (err, result) => {
        if (err) {
          console.log('error in loading')
          loading(false, `An Error Occurred in ${this.instrument}!`)

          appCallback(err, null)
        } else {
          appCallback(null, result)
        }
      }
    )
  }

  pageFinished = page => {
    this.pageAudioArray[page] = {}
  }

  playSound = (page, soundId, delay) => {
    this.pageAudioArray[page].play(soundId, delay)
  }

  prepareToPlay = (loadingCallback, ready) => {
    loadingCallback(true, 'Initialising Audio...')
    const that = this
    async.series(
      [
        callback => {
          that.seq = new Sequencer({
            pageStartTimes: this.pageStartTimes,
            pageDownStartTimes: this.relativeDownStartTimes,
            AudioObjArray: this.pageAudioArray,
            SEstartPs: this.startPs,
            pages: [this.pages[0]],
            pageDurs: this.pageDurs,
            inst: this.instrument,
            firstPageWaveform: this.firstPageWaveform,

            pages: this.pages
          })
          this.seq.init(ready, {
            inst: this.instrument,
            pages: [this.pages[0]],
            SEdurs: this.durs,
            numPages: this.pages.length,
            SEstartPs: this.startPs
          })
          callback(null, 'one')
        },
        (err, callback) => {
          that.seq.prepareToPlay(
            this.playSound,
            this.preload,
            loadingCallback,
            ready
          )
        }
      ],
      (err, result) => {
        if (err) {
          console.log(err)
        } else {
        }
      }
    )
  }

  play = (loadingCallback, finished) => {
    this.seq.play(loadingCallback, this.preload, this.playSound)
  }

  preload = (forceCache, id, callback) => {
    if (this.pages[id] == -1) {
      callback(null, { timeTaken: 0, page: -1, id: 0 })
      return
    }
    const page = this.pages[id]

    // Time the download;
    // reload cache so that a true download occurs.
    // Otherwise would be pulling straight from cache.
    // need to add this.

    // / i.e. fetch("some.json", {cache: "reload"})
    //    .then(function(response) { /* consume the response */ });
    async.series(
      [
        _callback => {
          const tA = performance.now()

          _callback(null, tA)
        },
        _callback => {
          this.pageAudioArray[id] = new AudioObjArray(
            this.fileUrls[id].page[page],
            _callback,
            page,
            id
          )
        }
      ],
      (err, result) => {
        if (err) {
          console.log(err)
          callback(err, 'null')
        } else {
          const tB = performance.now()
          callback(null, {
            timeTaken: tB - result[0],
            page: result[1].page,
            id: result[1].id
          })
        }
      }
    )
    if (forceCache) {
    } else {
    }
  }

  init = callback => {
    // preload first block of audio i.e. all the first pages.
    // take an average of the d ownload times over size and calculate time needed for initial buffering.
    this.preload(true, 0, callback)
  }

  pageLoaded = id => {
    this.pagesLoadedN.push(id)
  }

  calculatePageStartPoints = () => {
    const pageStartTimes = [0]
    let timeCount = 0
    for (const i in this.pageDurs) {
      timeCount += this.pageDurs[i] * 1000
      const index = parseInt(i) + 1
      pageStartTimes[index] = timeCount
    }

    return pageStartTimes
  }

  calculateProjectedDownloadTime = (time, callback) => {
    let totalSize = 0
    for (const i in this.pages) {
      totalSize += this.pageSizes[this.instrument][this.pages[i]]
    }

    const speed = this.pageSizes[this.instrument][this.pages[0]] / time // kb/ms

    // Speed should be sent to app.
    // below should be calculated using global slowest speed.

    // PageFlags are
    callback(null, { speed })
  }

  caluclateDownloadStartPoints = (speed, done) => {
    const projDownloadTime = [0]
    const pageSize = [0]
    const timeShortPPage = [0]
    this.relativeDownStartTimes = [0]
    for (let i = 1; i < this.pages.length; i++) {
      pageSize[i] = this.pageSizes[this.instrument][this.pages[i]]
      // Add 50% more time to be sure.
      projDownloadTime[i] = (pageSize[i] / speed) * 1.5
      // Calcualate when a file needs to be downloaded.
      timeShortPPage[i] =
        this.pageDurs[i - 1] * 1000 -
        (projDownloadTime[i] + 0.25 * (this.pageDurs[i - 1] * 1000))

      if (timeShortPPage[i] < 0) {
        this.relativeDownStartTimes[i] =
          this.pageStartTimes[i - 1] + timeShortPPage[i]
      } else {
        this.relativeDownStartTimes[i] = this.pageStartTimes[i - 1]
      }
    }
    done(null, 'finished')
  }

  getUrls = (callback, pages) => {
    if (typeof this.instrument !== 'undefined') {
      let urls = ''
      switch (this.instrument) {
        case 'bass':
          urls = bassJson
          break
        case 'bassoon':
          urls = bassoonJson
          break
        case 'cello':
          urls = celloJson
          break
        case 'clarinet':
          urls = clarinetJson
          break
        case 'flute':
          urls = fluteJson
          break
        case 'trombone':
          urls = tromboneJson
          break
        case 'trumpet':
          urls = trumpetJson
          break
        case 'tuba':
          urls = tubaJson
          break
        case 'piano':
          urls = pianoJson
          break
        case 'viola1':
          urls = viola1Json
          break
        case 'viola2':
          urls = viola2Json
          break
        case 'violin1':
          urls = violin1Json
          break
        case 'violin2':
          urls = violin2Json
          break
        case 'violin3':
          urls = violin3Json
          break
        default:
          break
      }
      // this.getApi((err, urls) => {
      //   if (err) {
      //     console.log(err)
      //     callback(err, null)
      //   } else {
          const pageUrls = pages.map(pageNum => urls[pageNum])
          callback(null, pageUrls)
      //   }
      // }, url)
    } else {
      callback(`Error: Issue with instrument name: ${this.instrument}`, null)
    }
  }

//   getApi (callback, getUrl) {
//     const that = this
//     this.serve
//       .get(getUrl, {
//         responseType: 'json',
//         validateStatus (status) {
//           return status >= 200 && status < 300 // default
//         }
//       })
//       .then(response => {
//         callback(null, response.data)
//       })
//       .catch(err => {
//         console.log(err)
//         callback(err, null)
//       })
//   }
}
