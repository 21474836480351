import { Slider, Checkbox } from '@mui/material/'
import Help from './Help'

export default class GenArr extends React.Component {
  constructor () {
    super()
  }

  state = {
    instN: -1,
    pageN: 0,
    hide: true,
    incPiano: true,
    incSilences: true,
    randomSizes: true,
    auto: false,
    open: false
  }

  changePageN = event => {
    const { value } = event.target
    this.setState({ pageN: value })
  }

  incPiano = (name, event) => {
    this.setState({ incPiano: !this.state.incPiano })
  }

  incSilences = (name, event) => {
    this.setState({ incSilences: !this.state.incSilences })
  }

  randomSizes = (name, event) => {
    this.setState({ randomSizes: !this.state.randomSizes })
  }

  changeInstN = event => {
    const { value } = event.target
    this.setState({ instN: value })
  }

  autoArrange = () => {
    this.setState({ auto: true })
  }

  render () {
    let display = 'none'
    if (this.props.show || !this.state.hide) {
      display = 'inline'
    }

    return (
      <div id='overlay' className='initDuration' style={{ display }}>
        {!this.state.auto ? (
          <div
            style={{
              position: 'absolute',
              width: '530px',
              height: '250px',
              top: '45%',
              left: '50%',
              margin: '-125px 0 0 -265px'
            }}
          >
            Automatically Generate Arrangement?
            <br />
            <br />
            <button id='buttonGlobal' onClick={this.autoArrange}>
              Auto
            </button>
            <button
              id='buttonGlobal'
              style={{ marginLeft: '25px' }}
              onClick={this.props.manualArrange}
            >
              Manual
            </button>
            <Help page={1} />
          </div>
        ) : (
          <div
            style={{
              position: 'absolute',
              width: '530px',
              height: '250px',
              top: '45%',
              left: '50%',
              margin: '-125px 0 0 -265px'
            }}
          >
            Number of Instruments:
            <br />
            {this.state.instN == -1 ? <div>Random</div> : this.state.instN}
            <Slider
              defaultValue={-1}
              step={1}
              min={-1}
              max={13}
              onChange={this.changeInstN}
            />
            <br />
            Number of Pages:
            <br />
            {this.state.pageN == 0 ? <div>Random</div> : this.state.pageN}
            <Slider
              defaultValue={0}
              step={1}
              min={0}
              max={16}
              onChange={this.changePageN}
            />
            <br />
            <br />
            Include Piano
            <Checkbox
              defaultChecked
              color='default'
              value='checkedG'
              inputProps={{
                'aria-label': 'checkbox with default color'
              }}
              onChange={() => {
                this.incPiano()
              }}
            />
            <br />
            Include Silences
            <Checkbox
              defaultChecked
              color='default'
              value='checkedG'
              inputProps={{
                'aria-label': 'checkbox with default color'
              }}
              onChange={() => {
                this.incSilences()
              }}
            />
            <br />
            Random Page Sizes
            <Checkbox
              defaultChecked
              color='default'
              value='checkedG'
              inputProps={{
                'aria-label': 'checkbox with default color'
              }}
              onChange={() => {
                this.randomSizes()
              }}
            />
            <br />
            <br />
            <button
              id='buttonGlobal'
              onClick={() => {
                this.props.generate(
                  this.state.instN,
                  this.state.pageN,
                  this.state.incPiano,
                  null,
                  this.state.incSilences,
                  this.state.randomSizes
                )
              }}
            >
              Accept
            </button>
            <button
              id='buttonGlobal'
              style={{ marginLeft: '25px' }}
              onClick={() => {
                this.props.manualArrange()
              }}
            >
              Manual
            </button>
            <Help page={2} />
          </div>
        )}
      </div>
    )
  }
}
