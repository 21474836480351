import Resizable from 're-resizable';
import React, { Component } from 'react';
import ItemDragBox from './itemDragBox';


export default class ItemDropped extends Component {
    constructor(props) {
        super();
        this.myRef = React.createRef();
        this.width = 0;
        this.windowWidth = window.innerWidth;
    }

    state = {
        atMinWidth: false,
        hover: false, 
        isMobileDevice: false
    }


    componentDidUpdate() {
        // console.log("id: " + this.props.id + " w: " + this.myRef.current.state.width);
        // this.props.checkWidthsOfBoxes();
    }

    UNSAFE_componentWillMount() {
        this.sendRef();
        const isMobile = this.isMobileDevice();
        this.setState({isMobileDevice: isMobile});
    }



    componentDidMount() {
        window.addEventListener('resize', this.windowResize);
        this.props.checkWidthsOfBoxes();
        this.width = this.myRef.current.state.width;
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.windowResize);
    }

    windowResize = () => {
        // console.log("WidowresizeHappened in itemDropped");
        // const widthRatio = window.innerWidth / this.windowWidth;
        // this.myRef.current.updateSize({ width: this.width * widthRatio, height: this.props.height });
        // this.windowWidth = window.innerWidth;
        // this.setState({ push: true });
        // this.forceUpdate();
    }


    updateSizings = () => {
        this.checkSize();
        this.props.checkWidthsOfBoxes();
        this.props.resizeHappened(this.props.id, this.myRef.current.state.width);
    }

    checkSize = () => {
        this.width = this.myRef.current.state.width;

        if (this.width <= this.props.minSize + 10) {
            this.setState({ atMinWidth: true });
        } else {
            this.setState({ atMinWidth: false });
        }
    }

    onResize = () => {

        // this.props.checkWidthsOfBoxes();

    }

    removeMe = () => {
        this.props.removeItem(this.props.id);
    }

    sendRef = () => {
        this.props.getRef(this.myRef, this.props.id);
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (typeof props.size !== 'undefined') {
            if (props.size.height <= 0 || props.size.width <= 0) {

            } else {
                this.myRef.current.updateSize({ width: props.size.width, height: props.size.height });
            }
        }
        if (props.check == 1) {
            this.checkSize();
            this.props.resizeChecked(props.id);
        }
    }


    isMobileDevice = () => {
        return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    };

    hovering = (bool) => {
        this.setState({hover: bool});
    }

    toggleChevrons = () => {
        this.setState(prevState => {
            const prevHover = prevState.hover;
            prevState.hover = !prevHover;
        });
    }


    render() {
        let background = '#e2e2e20';

        if(this.props.item.name == "silence") {
            background = "rgba(255,255,255,0.5)";
        }

       
        const style = {
            padding: 'auto',
            alignItems: 'center',
            justifyContent: 'center',
            border: 'solid 1px #ddd',
            MozUserSelect: 'none',
            WebkitUserSelect: 'none',
            msUserSelect: 'none',

        };

        let enable = {
 top: false, left: false, right: false, bottom: false, topLeft: false, topRight: false 
};
        if (this.props.editable) {
            enable = {
 top: false, left: true, right: true, bottom: false, topLeft: false, topRight: false 
};
        }

        let val;
        if (!Number.isInteger(parseInt(this.props.item.name[this.props.item.name.length - 2]))) {
            val = this.props.item.name[this.props.item.name.length - 1];
        } else {
            val = this.props.item.name[this.props.item.name.length - 2] + this.props.item.name[this.props.item.name.length - 1];
        }

        if (!Number.isInteger(parseInt(val))) {
            val = 's';
        }
        const { defWidth } = this.props;

        //console.log(" item=" + this.props.item + " id=" + this.props.id + " key=" + this.props.keyProp + " index=" + this.props.index + " atMinWidth=" + this.state.atMinWidth);

        return (
          <Resizable
            ref={this.myRef}
                // style={style}
            position="relative"
            className={this.key}
            minWidth={isNaN(this.props.minSize) ? 20 : this.props.minSize}
            maxWidth={this.props.inst == 'piano' ? this.props.minSize : 100000000}
            enable={enable}
            onResize={this.onResizeHandler}
            id={this.id}
            onResizeStop={this.updateSizings}
            defaultSize={{ width: defWidth, height: 'auto' }}
            onDragStart={this.preventDragHandler}
            onMouseEnter={() => {this.hovering(true)}}
            onMouseLeave={() => {this.hovering(false)}}
            onClick={this.toggleChevrons}
          >
              
            {this.props.editable
                    ? 
                        <div style={{height: '100%'}}>
                      <ItemDragBox
                        item={this.props.item}
                        id={this.props.id}
                        key={this.props.keyProp}
                        removeMe={this.removeMe}
                        handleDrop={this.props.handleDrop}
                        rearrange={this.props.rearrange}
                        index={this.props.index}
                        hovered={this.props.hovered}
                        background={background}
                        atMinWidth={this.state.atMinWidth}
                      />
                      {(this.state.hover || this.state.toggled) && this.state.isMobileDevice && <div style={{position: "absolute", left: '0', right: '0', top: 'calc(50% - 12.5px)', bottom: '0'}}>
                        <img id="button-img" style={{float: 'left', width: '25px' }} src="./media/icons/left_chevron.png" onClick={() => {this.props.moveOne(0,this.props.index)}} />
                        <img id="button-img" style={{float: 'right', width: '25px' }} src="./media/icons/right_chevron.png" onClick={() => {this.props.moveOne(1, this.props.index)}}/>
                        </div>
                        }
                    </div>
                    : (
                      <div className="uDBox" style={{background: background}}>
                        <span
                          id="itemText"
                          style={{
 margin: '40px auto auto auto', fontSize: '35px', textAlign: 'center', lineHeight: '99%', verticalAlign: 'middle' 
}}
                        >
                          {val}
                        </span>
                      </div>
)}
          </Resizable>

        );
    }
}
