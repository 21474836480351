import React, { Component } from 'react';

const text = [
    'John Cage’s Concert for Piano and Orchestra may be performed in many ways: each parts is designated as a ‘Solo’ and these may be combined differently, as solos (fourteen possibilities), as duos (91 possibilities), trios, quartets and so on up to the full ensemble of fourteen players.',
    'There is a total of 16,383 possible instrumental combinations but these are hardly represented by current recordings, which tend to be mostly full line-ups or smaller combinations and solos.',
    'Furthermore, the options of selecting material within each part are many, each part consisting of (with the exception of the Solo for Piano) either 12 or 16 pages, which may be of any duration.',
    'The Concert app allows the user to explore all of the possible instrumental combinations as well as the number, sequence and duration of pages.',
    'The Solo for Piano is treated differently, as fixed sequences of pages arranged in 15 parts – allowing the user to hear it as a complete sequence, from page one to page 63 if desired.',
    'Unlike existing recordings, each of which effectively documents, or ‘fixes’, one arrangement only, the Concert app allows the user to hear the Concert for Piano and Orchestra in many forms and durations, creating a fresh experience of the piece each time it is heard.',
    'The sounds themselves are, however, fixed, and in this way the app is also one particular reading of the Concert for Piano and Orchestra, performed by members of the ensemble Apartment House, to whom we are indebted for bringing their time and expertise to this project.',
    'The next screen will prompt you to set a duration for your arrangement. This can be changed at any time.'];

export default class Intro extends Component {
    state = {
        index: 0
    }
    
    componentDidMount() {

    }

    componentWillUnmount() {

    }


    next = () => {
        // go to next block of text
        const newIndex = (this.state.index) + 1;
        if (newIndex > text.length - 1) {
            this.props.hideMe();
            return;
        }
        this.setState({ index: newIndex });
    }

    back = () => {
        const newIndex = (this.state.index) - 1;
        if (newIndex < 0) {
            return;
        }
        this.setState({ index: newIndex });
    }

    skip = () => {
        // hide text
        this.props.hideMe();
    }


    handleClick = () => {
        setTimeout(this.next, 500);
    }

    



    render() {
        const onScreenText = this.state.index < text.length
            && text[this.state.index];
     

        const display = 'inline';
        const enableBack = this.state.index != 0;

        return (
          <div
            id="overlay"
            className="intro"
            style={{
 zIndex: '', display, width: '100%', height: '100%'
}}
          >
            <div style={{
                    position: 'absolute',
                    width: '750px',
                    height: '250px',
                    top: '50%',
                    left: '50%',
                    margin: '-125px 0 0 -375px'
                }}
            >
              <p>
                {onScreenText}
              </p>
                { enableBack &&
                    <button id="buttonGlobal" onClick={this.back} >Back</button>

                }
              <button id="buttonGlobal" onClick={this.next}>Next</button>
              <button style={{ position: 'fixed', bottom: '25px', left: 'calc(50% - 44px)' }} id="buttonGlobal" onClick={this.props.hideMe}>Skip</button>
                
            </div>
                
          </div>
        );
    }
}
