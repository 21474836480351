import React, { Component } from 'react';
import { DragSource } from 'react-dnd';

const itemSource = {
    beginDrag(props) {
        return props.item;
    },
    endDrag(props, monitor, component) {
        if (!monitor.didDrop()) {
            return;
        }

        return props.handleDrop(props.item.id);
    }
};

function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging(),
    };
}


class Item extends Component {
    constructor() {
        super();
    }


    state = {
        hover: false
    }


    hovering = () => {
        this.setState({ hover: true });
    }

    notHovering = () => {
        this.setState({ hover: false });
    }

    randomizeValue = () => {
        let random = 0;
        random = Math.floor(Math.random() / 0.33) + 0.66;
        return random;
    }


    render() {
        const { isDragging, connectDragSource, item } = this.props;
        const opacity = isDragging ? 0 : 1;
        const height = this.props.dropped ? '200px' : '50px';
        const background_url = this.props.dropped ? '' : 'media/icons/pageIcon@0.25x.png';
        const background_size = '50px 50px';
        const name = item.name == 'silence' ? item.name : item.name.split('page ')[1];

        const colour = this.state.hover ? '#d1d1d1' : 'white';
        let itemClassName = "item";
        if (this.state.hover) {
            itemClassName += " hovered";
        }
        return connectDragSource(
            <div
                className={itemClassName}
                onClick={() => {
                    this.props.handleDrop(this.state.id);
                }}
                style={{
                    opacity, height, width: '75px', margin: '0 5px', display: 'inline-block', backgroundColor: 'rgba(255, 255, 255, 0)', background: "url(" + background_url + ") no-repeat center", backgroundSize: background_size
                }}
                onMouseEnter={this.hovering}
                onMouseLeave={this.notHovering}
            >

                
                {
                    name != 'silence' ? (
                        <div>
                            <span id="itemText" style={{ marginTop: '14px', width: '100%', fontSize: '30px', display: 'block' }}>
                                {name}
                            </span>
                        </div>
                    )
                        : (
                            <div>
                                <span id="itemText" style={{ marginTop: '14px', width: '100%', fontSize: '30px', display: 'block' }}>
                                    s
                                </span>
                            </div>
                        )
                }
            </div>
        );
    }
}

export default DragSource('item', itemSource, collect)(Item);
