export default class Loading extends React.Component {
  constructor() {
    super();
  }
  
  render() {
    
    let loadingBox;
    if (this.props.loading) {
      loadingBox = (<div
        style={{position: 'fixed', top: '0', left: '0', width: '100%', height: 'calc(100% - 60px)', zIndex: '10', background: '#fff'}}>
        <p style={{position: 'fixed', textAlign: 'center', fontSize: '24px', width: '100%', top: '40%'}}>{this.props.message}</p>
        <div style={{position: 'fixed', left: '42%', right: '42%', top: '40%'}}>
          <div className="loader"></div>
        </div>
      </div>



      );
    } else {
      loadingBox = (<div />);
    }

    return (
      <div style={{width: '100%',  height: 'calc(100% - 50px'}}>
        {loadingBox}

      </div>
    );
  }
}
