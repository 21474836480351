import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog
} from '@mui/material'
import { Bold } from './Bold'

const enterDur = {
  title: 'Enter Duration Help',
  text: (
    <div>
      Enter a duration in:
      <Bold>Hours:Minutes:Seconds</Bold>
      and press enter to continue!
    </div>
  )
}

const autoOrMan = {
  title: 'Automatic or Manual Arrangement Help',
  text: (
    <div>
      <div>
        <Bold>"Auto"</Bold>
        This button will take you to a new page where you can select a number of
        instruments and rough number of pages for your arrangement and generate
        a random composition using these parameters.
      </div>

      <div style={{ marginTop: '10px' }}>
        <Bold>"Manual"</Bold>
        This button will take you through to the arrangement where you can start
        a new arrangement from scratch.
      </div>
    </div>
  )
}

const autoParams = {
  title: 'Automatic Generation Parameters',
  text: (
    <div>
      <div>
        <Bold>"Number of Instruments"</Bold> This slider will determine how many
        instruments from the orchestra, from Double Bass to Tuba, you want to be
        automatically generated.
      </div>
      <div style={{ marginTop: '10px' }}>
        <Bold>"Number of Pages"</Bold> This slider will roughly determine the
        number of pages per instrument you want to hear, but the actual number
        of pages which are generated will depend on the duration you have set.
      </div>
      <div style={{ marginTop: '10px' }}>
        <Bold>"Include piano"</Bold> This check box will toggle whether to
        inclde the piano or not.
      </div>
      <div style={{ marginTop: '10px' }}>
        <Bold>"Include Silences"</Bold> This check box will toggle whether some
        of the generated pages are silences.
      </div>
      <div style={{ marginTop: '10px' }}>
        <Bold>"Random Page Sizes"</Bold> This check box will toggle whether
        pages added are the same length or are randomly sized.
      </div>
    </div>
  )
}

const manualPage = {
  title: 'Manual Arrangement: ',
  text: (
    <div>
      <div>
        <Bold>"Info"</Bold>
        This button takes you back to the introduction explaining the project
        and the app.
      </div>
      <div></div>

      <div style={{ marginTop: '10px' }}>
        <Bold>"Generate"</Bold>
        This button takes you to the automatic arrangement generation screen
        where you can set parameters and generate a const random composition.
      </div>
      <div></div>

      <div style={{ marginTop: '10px' }}>
        <Bold>"Clear"</Bold>
        This button clears your current arrangement and allows you to start from
        scracth.
      </div>
      <div></div>

      <div style={{ marginTop: '10px' }}>
        <Bold>"Help"</Bold> This button brings up this dialog"
      </div>
      <div></div>

      <div style={{ marginTop: '10px' }}>
        <Bold>"+"</Bold>
        The magnifying glass with a plus increases the size of each instrument
        track.
      </div>
      <div></div>

      <div style={{ marginTop: '10px' }}>
        <Bold>"-"</Bold>
        The magnifying glass with a minus decreases the size of each instrument
        track.
      </div>
      <div></div>

      <div style={{ marginTop: '10px' }}>
        <Bold>{'>'}</Bold>
        The right facing triangle starts the playback of audio and waveform
        visualisation. At any point playback can be stopped or paused in the
        playback screen. Any instruments with no pages will be ignored.
      </div>
      <div></div>

      <div style={{ marginTop: '10px' }}>
        <Bold>"00:10:00"</Bold>
        Clicking on the duration in the corner will take you back to the "Enter
        Duration" page and allow you to change the duration of the arrangement.
        A warning will be displayed if the new duration is too short.
      </div>
    </div>
  )
}

export default class Help extends React.Component {
  state = {
    open: false
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render () {
    let title
    let text
    switch (this.props.page) {
      case 0:
        title = enterDur.title
        text = enterDur.text
        break
      case 1:
        title = autoOrMan.title
        text = autoOrMan.text
        break
      case 2:
        title = autoParams.title
        text = autoParams.text
        break
      case 3:
        title = manualPage.title
        text = manualPage.text
        break
    }

    let style = {
      position: 'fixed',
      bottom: '25px',
      width: '100px',
      left: 'calc(50% - 50px)'
    }

    if (typeof this.props.style !== 'undefined') {
      style = this.props.style
    }

    return (
      <div style={style}>
        <button id='buttonGlobal' onClick={this.handleClickOpen}>
          Help?
        </button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description' component={'span'}>
              {text}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button onClick={this.handleClose}>Okay</button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
