import React, { Component } from 'react'
import Help from './Help'

export default class Timeline extends Component {
  constructor () {
    super()

    this.timeRefs = {
      H: React.createRef(),
      M: React.createRef(),
      S: React.createRef()
    }
    this.hrs = 0
    this.min = 0
    this.sec = 0
    this.duration = 0
  }

  state = {
    minVal: 0,
    playMode: false,
    hrs: 0,
    min: 0,
    sec: 0,
    duration: 0,
    durHover: false
  }

  valueChange = event => {
    event.target.value = event.target.value
    const timeVal = event.target.value
    const min = this.convertToHMSret(this.minVal)
    let newTime = { hrs: this.hrs, min: this.min, sec: this.sec }

    if (event.target.id !== 'slider') {
      newTime[event.target.id] = event.target.value
      if (this.convertHMStoSec(newTime) < this.minVal) {
        newTime = this.convertToHMSret(this.minVal)
      }
    } else if (event.target.value < this.minVal) {
      newTime = this.convertToHMSret(this.minVal)
    } else {
      newTime = this.convertToHMSret(event.target.value)
    }
    if (newTime.hrs <= min.H) {
      if (newTime.min <= min.M) {
        if (newTime.sec < min.S) {
          newTime = { hrs: min.H, min: min.M, sec: min.S }
        } else {
        }
      }
    }

    if (newTime.sec >= 60) {
      newTime.min += Math.floor(newTime.sec / 60)
      newTime.sec %= 60
    }

    if (newTime.min >= 60) {
      newTime.hrs += Math.floor(newTime.min / 60)
      newTime.min %= 60
    }

    if (newTime.sec <= -1) {
      newTime.min -= Math.ceil(newTime.sec / 60)
      newTime.sec %= 60
    }

    if (newTime.min <= -1) {
      newTime.hrs -= Math.ceil(newTime.min / 60)
      newTime.min %= 60
    }

    // this.setState({hrs: newTime.hrs, min: newTime.min, sec: Math.round(newTime.sec)});
    this.hrs = newTime.hrs
    this.min = newTime.min
    this.sec = Math.round(newTime.sec)
  }

  convertToHMS = duration => {}

  convertToHMSret = duration => {
    const H = Math.floor(duration / 3600)
    duration %= 3600
    const M = Math.floor(duration / 60)
    const S = duration % 60
    return { H, M, S }
  }

  convertHMStoSec (HMS) {
    return HMS.H / 360 + HMS.M / 60 + HMS.S
  }

  UNSAFE_componentWillReceiveProps (props) {}

  play = () => {
    this.setState({ playMode: true })
    this.props.setPlay(this.duration)
    this.props.play(this.duration)
  }

  stop = () => {
    this.setState({ playMode: false })
    this.props.setStop()
  }

  resume = () => {
    this.setState({ playMode: true })
    this.props.resume()
  }

  setMinimumDuration = () => {
    const durations = this.props.getDurations()
    if (isNaN(durations.minDur) || isNaN(durations.addedDur)) {
      return
    }
    this.minVal = durations.minDur
    this.duration = durations.addedDur

    const newHMS = this.convertToHMSret(Math.ceil(durations.addedDur))
    this.hrs = newHMS.H
    this.min = newHMS.M
    this.sec = newHMS.S
  }

  showHelpText = () => {
    this.setState({ durHover: true })
  }

  hideHelpText = () => {
    this.setState({ durHover: false })
  }

  componentDidUpdate () {}

  render () {
    this.setMinimumDuration()
    const HMS = this.convertToHMSret(this.props.durationSet)
    const timePassed = this.convertToHMSret(
      Math.round(this.props.timePassed / 1000)
    )
    const hoverHelp = this.state.hover

    const styles = props => ({
      colorPrimary: {
        backgroundColor: '#00695C'
      },
      barColorPrimary: {
        backgroundColor: '#B2DFDB'
      }
    })
    var stopEnable = true
    var stopOpacity = 1

    if (this.props.audioLoading) {
      stopEnable = false
      stopOpacity = 0.5
    }

    return (
      <div className='Timeline'>
        {!this.state.playMode && (
          <div className='navigationButtons'>
            <button id='buttonGlobal' onClick={this.props.showIntro}>
              Info
            </button>
            <button id='buttonGlobal' onClick={this.props.openGenPage}>
              Generate
            </button>
            <button id='buttonGlobal' onClick={this.props.clear}>
              Clear
            </button>
            <Help page={3} style={{ display: 'inline-block' }} />
          </div>
        )}

        <div className='reset' />

        <div
          className='duration'
          onMouseEnter={this.showHelpText}
          onMouseLeave={this.hideHelpText}
        >
          {!this.state.playMode ? (
            <button onClick={this.props.changeDuration}>
              {' '}
              {HMS.H < 10 ? 0 + String(HMS.H) : HMS.H} :{' '}
              {HMS.M < 10 ? 0 + String(HMS.M) : HMS.M} :{' '}
              {HMS.S < 10 ? 0 + String(HMS.S) : HMS.S}
            </button>
          ) : (
            <span style={{ fontSize: '30px' }}>
              {timePassed.H < 10 ? 0 + String(timePassed.H) : timePassed.H} :{' '}
              {timePassed.M < 10 ? 0 + String(timePassed.M) : timePassed.M} :{' '}
              {timePassed.S < 10
                ? 0 + String(Math.round(timePassed.S))
                : timePassed.S}
            </span>
          )}
        </div>

        <div className='play'>
          {!this.state.playMode ? (
            <button
              id='img-button'
              onClick={this.play}
              style={{ padding: '10px 0 0 0' }}
            >
              <img
                id='button-img'
                style={{ opacity: stopOpacity, height: '20px', width: '15px' }}
                src='./media/icons/play.png'
              />
            </button>
          ) : (
            <div>
              <div>
                {!this.props.pauseState ? (
                  <button
                    onClick={this.props.pause}
                    id='img-button'
                    style={{ padding: '10px 0 0 0' }}
                  >
                    <img
                      id='button-img'
                      style={{
                        opacity: stopOpacity,
                        height: '20px',
                        width: '15px'
                      }}
                      src='./media/icons/pause.png'
                    />
                  </button>
                ) : (
                  <button
                    id='img-button'
                    onClick={this.resume}
                    style={{ padding: '10px 0 0 0' }}
                  >
                    <img
                      id='button-img'
                      style={{
                        opacity: stopOpacity,
                        height: '20px',
                        width: '15px'
                      }}
                      src='./media/icons/play.png'
                    />
                  </button>
                )}
                <button
                  id='img-button'
                  onClick={() => {
                    if (stopEnable) {
                      this.stop()
                    }
                  }}
                  style={{ padding: '10px 0 0 0' }}
                  disabled={!stopEnable}
                >
                  <img
                    id='button-img'
                    style={{
                      opacity: stopOpacity,
                      height: '20px',
                      width: '15px',
                      marginLeft: '10px'
                    }}
                    src='./media/icons/stop.png'
                  />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

// ///////<button id="img-button" onClick={this.props.zoomIn}>
// <img id="button-img" style={{position: 'absolute', height: '30px', width: '30px', right: '230px',  bottom: '15px' }} src="./media/icons/magnify.png" />
// </button>
// <button id="img-button" onClick={this.props.zoomOut}>
//   <img id="button-img" style={{position: 'absolute', height: '30px', width: '30px', paddingLeft: '10px', right: '275px', bottom: '15px' }} src="./media/icons/demagnify.png" />
// </button>
