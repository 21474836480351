import NumberFormat from 'react-number-format';

export default class DurErr extends React.Component {
  constructor(props) {
    super();
    this.pianoDurErr = false;
    this.durErrUndoOpt;
    this.durErrMatchOpt;
  }

  state = {
    numbersEntered: false,
    hide: true,
    matchHover: false,
    undoHover: false,
  }

  componentDidMount() {
    this.durErrMatchOpt = document.getElementById("durErrMatchOpt");
    this.durErrUndoOpt = document.getElementById("durErrUndoOpt");
  }

  onMouseEnter  =(e)=> {    
    if(e.target.className == "match") {

      this.durErrMatchOpt.classList.toggle('is-hovered');
      this.setState({undoHover: false, matchHover: true})
    } else {
   
      this.durErrUndoOpt.classList.toggle('is-hovered');
      this.setState({undoHover: true, matchHover: false})
    }
  }

  onMouseLeave =(e)=> {
    if(e.target.className == "match") {
      this.setState({undoHover: false, matchHover: false})
        this.durErrMatchOpt.classList.toggle('is-hovered');
    } else {
        this.durErrUndoOpt.classList.toggle('is-hovered');
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.pianoDurErr != this.pianoDurErr) {
      this.pianoDurErr = newProps.pianoDurErr;
    }
  }


  convertToHMSret = (duration) => {
    let H = Math.floor(duration / 3600);
    duration %= 3600;
    let M = Math.floor(duration / 60);
    let S = duration % 60;
    if (H < 10) {
      H = `0${H}`;
    }
    if (M < 10) {
      M = `0${M}`;
    }
    if (S < 10) {
      S = `0${S}`;
    }

    return { H, M, S };
  }


  render() {
    let display = 'none';
    if (this.props.show || !this.state.hide) {
      display = 'inline';
    }
    
    const selDur = this.convertToHMSret(this.props.selDur);
    const overDur = this.convertToHMSret(this.props.overDur);
    return (
      <div
        className="initDuration"
        id="overlay"
        style={{ display }}
      >

        <div style={{
          position: 'absolute',
          width: '530px',
          height: '250px',
          top: '50%',
          left: '50%',
          margin: '-125px 0 0 -265px'
        }}
        >
          {
            !this.pianoDurErr
              ? <div>Duration too short for the last added selection...</div>
              : <div>Addition of piano requires a minimum duration of: </div>
          }
          <br />
          <div style={{ marginBottom: '15px' }}>
            <div id={'durErrMatchOpt'} className={'durErrMatchOpt'}  style={{ display: "inline-block", marginRight: '25px', textAlign: 'center'}}>
              <h4 style={{color: 'inherit'}}>Selected Duration: </h4>
              <h3 style={{color: 'inherit', fontWeight: '300'}}>{selDur.H}
              :
                {selDur.M}
              :
                {selDur.S}
              </h3>
            </div>
            <div  id={'durErrUndoOpt'} className={'durErrUndoOpt'}style={{ display: "inline-block",  textAlign: 'center'}}>
              <h4 style={{color: 'inherit'}}>Required Duration: </h4>
              <h3 style={{color: 'inherit', fontWeight: '300'}}>{overDur.H}
              :
                {overDur.M}
              :
                {overDur.S}
                </h3>
            </div>
          </div>
          {
            !this.pianoDurErr
              ? (
                <button
                  id="buttonGlobal"
                  className="undo"
                  onClick={() => this.props.removeLastItem(this.props.inst, this.props.lastPage)}
                  onMouseEnter={this.onMouseEnter}
                  onMouseLeave={this.onMouseLeave}
                >
                  Undo
                           </button>
              )
              : <button id="buttonGlobal" className="undo" 
                  onClick={this.props.removePiano} 
                  onMouseEnter={this.onMouseEnter}
                  onMouseLeave={this.onMouseLeave}>Remove Piano</button>
          }
          <button
            style={{ marginLeft: '25px' }}
            className="match"
            id="buttonGlobal"
            onClick={() => {
              this.props.setDuration(this.props.overDur, this.pianoDurErr);
              this.pianoDurErr = false;
            }}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
          >
            Match
              </button>


        </div>
      </div>
    );
  }
}


// / // <button style={{margin: "50px 0"}} className="submitDuration" id="buttonGlobal">→</button>

// / <NumberFormat style={{ margin: "20px 0 0 0", padding: "auto", border: "none", fontSize: "35px", fontFamily: 'Merriweather', textAlign: "center", borderRadius:"10px"}} format="##:##:##" placeholder="HH:MM:SS"  onValueChange={this.enteredNumbers} />
