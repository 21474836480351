import GetAudioWFD from '../Data/getAudioWaveformData.jsx';
import { isNullOrUndefined } from 'util';

export default class WFDPlot {
    constructor() {
        this.resizeTimer;
        
        this.yBuf = [];
        this.xBuf = [];
        this.buf = [];
        this.resizeNow = true;
        this.targetWidth = 0;
        this.progress = 0;
        this.totalJobs = 10;
        
        this.initLoading = true;
        this.flipflop = 0;
        this.gAWFD;
        this.startPs;
        this.inst;
        this.pageDurs;
        this.pagesDurs;
        this.canvasTimer;
        this.width;
        this.height;
        this.dpr = window.devicePixelRatio || 1;
        this.canvasT_ctx;
        this.widthT;
        this.heightT;
        this.numPages;
        this.currentPage = 0;
        this.waveforms = [];
        this.sampleoffsets = [];

        this.gwidth;
        this.gheight;
        this.resizeId;
        this.g;
        this.svgTag;
    }


    init = (props, resolve, reject) => {

        this.gAWFD = new GetAudioWFD();
        this.startPs = props.startPs;
        this.inst = props.inst;
        this.durs = props.SEdurs;
        this.pageDurs = props.pageDurs;
        this.pagesDurs = props.pagesDurs;
        
        this.svgTag = `#target_${props.inst}_A`;
        this.canvasTimer = document.getElementById(`target_${props.inst}_T`);
        const canvasObj = document.getElementById(`target_${props.inst}_A`);
        this.canvasA = d3.select(canvasObj);

        this.width = canvasObj.getBoundingClientRect().width;
        this.height = canvasObj.getBoundingClientRect().height;

        this.canvasT_ctx = this.setupCanvas(this.canvasTimer, this.dpr);
        this.widthT = canvasObj.getBoundingClientRect().width * this.dpr;
        this.heightT = canvasObj.getBoundingClientRect().height * this.dpr;
        this.numPages = props.numPages; 
        
        const margin = {
            top: 5, right: 0, bottom: 5, left: 0 
        };
        this.gwidth = this.width - margin.left - margin.right;
        this.gheight = this.height - margin.top - margin.bottom;
        
        this.g = this.canvasA.append('g')
             .attr('width', this.width)
             .attr('height', this.height)
            .attr('transform',
                `translate(${margin.left},${margin.top})`)
            .attr('shape-rendering', 'geometricPrecision');
        resolve();
    }


    UNSAFE_componentWillReceiveProps(props) {
        if (props.windowSize != this.props.windowSize && this.state.resizeNow) {
            this.setState({ targetWidth: props.windowSize, resizeNow: false });
        }
    }

    resize = (width, height, page) => {
        this.width = width;
        if(isNullOrUndefined(height) || height <= 0) {
            this.height = this.canvasTimer.getBoundingClientRect().height;
        } else {
            this.height = 250 - height;
        }

        const margin = {
 top: 5, right: 0, bottom: 5, left: 0 
};
        this.gwidth = this.width - margin.left - margin.right;
        this.gheight = this.height - margin.top - margin.bottom;

        this.draw(page)
    }

    resizeCoolDownTimer = () => {
        this.resizeTimer = setTimeout(() => {
            this.setState({ resizeNow: true });
        }, 50);
    }

    emptyBuffer = (i) => {
        this.buf[i] = [];
    }





    getWaveformData = (inst, page, bufID) => {
        if(page == -1) {
            
            this.constructWaveform([0,0], [0,0], bufID);
        } else {
            this.gAWFD.getPageData({ inst, page, size: 0 }, (dat) => {
                this.constructWaveform(dat, this.offsets, bufID);
              });
        }
       
        this.progress++;
    }


    draw = (id) => {
        if (this.buf.length < 1) {
            return;
        }
        if (id >= this.numPages) {
            return;
        }
        if (this.g.select('path')) {
            this.g.select('path').remove();
        }

        const x = d3.scaleLinear().rangeRound([0, this.gwidth]);
        const y = d3.scaleLinear().rangeRound([this.gheight, -0.1]);
        const xRange = d3.extent(this.buf[id], (d) => d.x);
        const yRange = d3.extent(this.buf[id], (d) => d.y);
        var yMax; 

        if(Math.abs(yRange[0]) > Math.abs(yRange[1])) {
            yMax = Math.abs(yRange[0]); 
        } else {
            yMax = Math.abs(yRange[1]);
        }

        const line = d3.line()
            .x((d) => x(d.x))
            .y((d) => y(d.y));
        x.domain(xRange);// this.xBuf[id].waveform));
        y.domain([-yMax, yMax]);// this.yBuf[id].waveform));

        this.g.append('path')
            // .datum({x: this.yBuf[id].waveform, y: this.yBuf[id].waveform})
            .datum(this.buf[id])
            .attr('fill', '#44423f')
            .attr('stroke-linejoin', 'round')
            .attr('stroke-linecap', 'round')
            .attr('stroke', '#44423f')
            .attr('stroke-width', 1)
            .attr('d', line);
    }


    drawScrollArm(perc) {
        const xLoc = (perc * this.width);
        this.canvasT_ctx.clearRect(0, 0, this.width, this.height);
        this.canvasT_ctx.beginPath();
        this.canvasT_ctx.lineWidth = 0.5;
        this.canvasT_ctx.moveTo(xLoc, 0);
        this.canvasT_ctx.lineTo(xLoc, this.height);
        this.canvasT_ctx.stroke();
        this.canvasT_ctx.closePath();
    }

    addToBuffers = (inst, page, id, waveformLoaded, ready) => {
        new Promise((resolve, reject) => {
            if(page == -1)
                resolve(["0,0"]);
            else
                this.gAWFD.getPageData({ inst, page }, resolve);
        }).then((data) => {
            this.buf.push({ id, page, waveform: [] });
            const Promises = [];
            for (const i in data) {
                if (data[i] != null) { Promises.push(this.constructWaveform(id, i, data[i].split(',').map(Number))); }
                // yPromises.push(this.constructYWaveform(data, id, i));
            }
            Promise.all(Promises).then((results) => {
                this.buf[id] = [{ x: 0, y: 0 }];
                for (const i in results) {
                    Array.prototype.push.apply(this.buf[id], results[i]);
                }
                this.buf[id].push({ x: this.pageDurs[id] * 1, y: 0 });
                
                if (id == 0) {
                        this.initLoading = false;
                        waveformLoaded(ready);
                        // supersuper.initWaveformLoaded(this.inst);
                }
            }).catch((e) => {
                console.log(e);
            });

        });
    
    }

    constructYWaveform = (data, id, i) => new Promise((resolve, reject) => {
            resolve(Array.prototype.push.apply(this.yBuf[id].waveform, data[i].split(',').map(Number)));
        })

    constructWaveform = (id, i, data) => new Promise((resolve, reject) => {
            let dur;
            if (this.inst == 'piano') {
                    dur = this.pageDurs[id];

                
            } else {
                    dur = this.durs[id][i]; 

            }
            if(typeof this.startPs[id][i] == "undefined") {
                resolve(this.getXVals(0, dur, data));
            } else {
                resolve(this.getXVals(this.startPs[id][i], dur, data));
            }
        })

    show = (page) => {
        this.draw(page);
        this.currentPage = page;
    }

    stop = () => {

    }

    pause = () => {

    }

    getXVals = (offset, dur, data) => {
        const temp = [];
        for (let i = 0; i < data.length; i++) {
            temp[i] = { x: (offset) + (i / data.length) * dur, y: data[i] };
        }

        return temp;
    }

    setupCanvas = (canvas, dpr) => {
        // Get the device pixel ratio, falling back to 1.

        // Get the size of the canvas in CSS pixels.
        const rect = canvas.getBoundingClientRect();
        // Give the canvas pixel dimensions of their CSS
        // size * the device pixel ratio.
        canvas.width = rect.width * dpr;
        canvas.height = rect.height * dpr;
        const ctx = canvas.getContext('2d');
        // Scale all drawing operations by the dpr, so you
        // don't have to worry about the difference.
        ctx.scale(dpr, dpr);
        return ctx;
    }
}
