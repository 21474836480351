import fileTimings from "../../static/res/info/fileTimings.json";
import instrumentalists from "../../static/res/info/instrumentalists.json";

export default class GetInstData {
  getData(instrument, _resolve) {
    _resolve(fileTimings[instrument]);
  }

  getMinimumDurations(instrument, offset, callback) {
    instrument = instrument.split(" ");
    if (instrument.constructor === Array) {
      if (instrument.length == 2) {
        instrument = instrument[0] + instrument[1];
      }
    }

    let data = fileTimings[instrument];

    const pages = [];
    let max = 0;

    Object.entries(data).map((page, i) => {
      let timingsSum = 0;

      page[1].forEach((SE) => {
        timingsSum += SE + offset;
      });
      pages[i] = Math.ceil(timingsSum) + 5;
      if (pages[i] > max) {
        max = pages[i];
      }
    });

    callback({
      pages,
      max,
    });
  }

  getInstrumentalistInfo(instrument, callback) {
    instrument = instrument.split(" ");
    if (instrument.constructor === Array) {
      if (instrument.length == 2) {
        instrument = instrument[0] + instrument[1];
      }
    }
    callback(instrumentalists[instrument]);
  }
}
