
// import axios from 'axios';

function getFromServer(URL, port, resolve) {
    const urlPrefix = "./";//`${location.protocol}//${location.hostname}/`;
    // console.log({ URL });
    return axios({
      method: 'get',
      url: urlPrefix + URL
    }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      // console.log(error.config);
      resolve(null);
    });
  }


function axiosGet(URL, resolve, reject, retries) {
    const urlPrefix = "/";//`${location.protocol}//${location.hostname}/`;
    return axios({
        method: 'get',
        url: urlPrefix + URL,
        timeout: 5000
    }).then((response) => {
        resolve({
error: null,
            data: response.data,
            retries: 0
});
    })
        .catch((error) => {
            if (error.response) {
                /*
                 * The request was made and the server responded with a status code
                 * That falls out of the range of 2xx
                 */
                console.log(error);
                console.log(error.response.status);
                console.log(error.response.headers);
                reject({
error,
                    data: null,
                    retries: retries++ 
});
                return;
            } if (error.request) {
                /*
                 * The request was made but no response was received
                 * `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                 * Http.ClientRequest in node.js
                 */
                console.log(error.request);
                reject({
error,
                    data: null,
                    retries: retries++
});
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log(
                    'Error',
                    error.message
                );
                reject({
 error,
                    data: null,
                    retries: retries++
});
            }
            console.log(error.config);
        });
}


export {getFromServer, axiosGet}