import {
  waveformFilesLoc
} from '../appContext'


export default class GetAudioWFD {
  constructor(props) { }

  getPageData = (props, callback) => {
    let inst;
    import('../../static/res/info/instrumentPages.json').then(instrumentPages => {
      new Promise((resolveInner, reject) => {
        inst = instrumentPages.info.find(inst => inst.part === props.inst)
        const lrg = true
        const pageOffset = inst.pages[0].absoluteNumber

        let SEN = inst.pages[props.page].soundEvents.length
        if (inst.part === 'piano') SEN = 1

        const waveformURLS = []
        let suffix = '_SML.txt'
        if (lrg) {
          suffix = '_LRG.txt'
        }

        let page = pageOffset + props.page

        let idx
        for (let i = 0; i < SEN; i++) {
          idx = i + 1
          if (idx < 10) {
            idx = `0${idx.toString()}`
          }
          waveformURLS[i] = `${waveformFilesLoc}/${props.inst.charAt(0).toUpperCase() + props.inst.slice(1)}/${props.inst.charAt(0).toUpperCase() + props.inst.slice(1)
            }-${page}-${idx}${suffix}`
        }
        resolveInner(waveformURLS)
      }).then(pageData => {
        new Promise((resolve, reject) => {
          const pageWaveformData = []
          let i
          for (i = 0; i < pageData.length; i++) {
            pageWaveformData.push(this.collateSoundEvents(pageData[i]))
          }

          Promise.all(pageWaveformData)
            .then(results => {
              resolve(results)
            })
            .catch(e => {
              console.log(e)
            })
        }).then(pageWaveformData => {
          new Promise((resolve, reject) => {
            resolve(this.sendWaveformData(pageWaveformData, callback))
          })
        })
      })
    })
  }

  collateSoundEvents = SE =>
    new Promise((resolve, reject) => {

      SE = SE.replace('bassoon', 'sax')
      SE = SE.replace('bassoon', 'sax')
      this.axiosGet(SE, resolve)

    })

  sendWaveformData = (pageWaveformData, callback) => {
    callback(pageWaveformData)
  }

  axiosGet = async (URL, resolve) => {
    try {
      const response = await fetch(URL);
  
      if (response.ok) {
        const textData = await response.text();
        resolve(textData);
      } else {
        console.error('Response was not OK:', response.statusText);
      }
    } catch (err) {
      console.error('Fetch error:', err);
    }
  };
}
