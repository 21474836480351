import React, { Component } from 'react'
import { DropTarget } from 'react-dnd'
import ItemDropped from './ItemDropped'

function collect (connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    hovered: monitor.isOver(),
    item: monitor.getItem(),
    didDrop: monitor.didDrop(),
    canDrop: monitor.canDrop()
  }
}

const itemTarget = {
  canDrop (props, monitor) {
    if (props.addedItems.length > 0) {
      return true
    }
    return true
  }
}

class Target extends Component {
  silenceCount = 0

  constructor (props) {
    super()
    this.checkSizes = true
    this.pageBoxRefs = []
    this.boxWidths = []
    this.totalSize = 0
    this.totalTime = 0
    this.tempWidth = 0
    this.targetID = `target_${props.instrument.replace(/\s/g, '')}`
    this.dragHover = { pos: 9000, toggle: false }
    this.dragSizeOverride
    this.targetSizeOverride
    this.initialPass = false
    this.shouldUpdate = true
    this.resizeCheckResult = 0
    this.checkArray = []
    this.justRemoved = false
    this.rearranged = false
    this.previousTargetWidth = 1
    this.keys = []
  }

  state = {
    items: [],
    boxWidths: [],
    addedItems: [],
    playing: false,
    timeUnit: 0,
    targetWidthFound: false
  }

  UNSAFE_componentWillMount () {
    if (typeof this.props.autoSelection !== 'undefined') {
      this.setState({ addedItems: this.props.autoSelection })
    }
  }

  canDrop = (props, monitor) => {
    if (props.addedItems.length > 0) {
      return true
    }
    return true
  }

  passSelections = (boxWidths, addedItems) => {
    const pageDurs = boxWidths.map(boxWidth => boxWidth * this.timeUnit)
    const items = addedItems.map(item => item.id)

    const pages = []
    for (const i in pageDurs) {
      pages[i] = { id: items[i], dur: pageDurs[i] }
    }
    const selections = { pages }
    this.props.passSelections({ selections, inst: this.props.selection })
  }

  removeItem = id => {
    this.boxWidths.splice(id, 1)
    this.setState(prevState => {
      const { addedItems } = prevState
      // addedItems.splice(id, 1);
      this.pageBoxRefs = []
      for (const i in addedItems) {
        this.pageBoxRefs.push(React.createRef())
      }
      prevState.addedItems = addedItems
      return prevState
    })
    this.props.removeItem(id)
  }

  rearrange = (dragIndex, hoverIndex, id) => {
    this.dragHover.pos = hoverIndex
    this.dragHover.toggle = true
    this.forceUpdate()
  }

  rearrangeDrop = itemID => {
    // this.dragSizeOverride = { id: itemID, width: this.boxWidths[itemID], height: (250 - this.props.boxHeight) + 'px' };
    // this.dropSizeOverride = { id: this.dragHover.pos, width: this.boxWidths[this.dragHover.pos], height: (250 - this.props.boxHeight) + 'px' };

    this.setState(prevState => {
      const items = prevState.addedItems
      const dragItem = prevState.addedItems[itemID]
      items.splice(itemID, 1)
      items.splice(this.dragHover.pos, 0, dragItem)
      prevState.addedItems = items
    })

    this.dragHover.pos = 9999
    this.dragHover.toggle = false
  }

  componentDidMount () {
    // this.setItems();

    window.addEventListener('resize', this.windowResize)
  }

  windowResize = () => {}

  componentDidUpdate () {
    this.checkWidthsOfBoxes()
  }

  checkWidthsOfBoxes = () => {
    if (!this.props.playMode) {
      let boxWidthSum = 0
      for (let i = 0; i < this.pageBoxRefs.length; i++) {
        if (this.pageBoxRefs[i].current !== null) {
          this.boxWidths[i] =
            this.pageBoxRefs[i].current.resizable.getBoundingClientRect().width
          boxWidthSum += this.boxWidths[i]
        } else {
          return 0
        }
      }

      const boxNormSpan = this.roundToThree(
        (1 / this.props.targetWidth) * boxWidthSum
      )

      this.props.getSelections(
        this.state.addedItems,
        this.props.instrument,
        this.boxWidths
      )

      return boxWidthSum
    }
  }

  getBoxSizes = () =>
    this.pageBoxRefs.map(
      boxRef =>
        boxRef.current.myRef.current.resizable.getBoundingClientRect().width
    )

  roundToThree = num => +`${Math.round(`${num}e+3`)}e-3`

  getRef = (ref, id) => {
    this.pageBoxRefs[id] = ref
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (nextProps.addedItems != this.state.addedItems) {
      this.setState({ addedItems: nextProps.addedItems })
    }
    if (nextProps.zoomed) {
      //onsole.log("target should update: zoomed")
      nextProps.zoomAccepted()
      return true
    }
    if (this.rearranged) {
      this.rearranged = false
      return true
    }
    if (typeof nextProps.durErr.show !== 'undefined') {
      if (nextProps.durErr.show) {
        return false
      }
    }
    if (nextProps.autoSelsAdded) {
      return true
    }
    if (
      !this.props.editable &&
      !this.props.playMode &&
      this.initialPass &&
      !this.shouldUpdate
    ) {
      return false
    }
    this.initialPass = true
    return true
  }

  moveOne = (dir, id) => {
    switch (dir) {
      case 0:
        if (id > 0)
          this.setState(prevState => {
            let addedItems = prevState.addedItems
            let removed = addedItems.splice(id, 1)
            addedItems.splice(id - 1, 0, removed[0])
            this.rearranged = true
            this.props.rearranged(addedItems)
          })

        break
      case 1:
        if (id < this.state.addedItems.length - 1)
          this.setState(prevState => {
            let addedItems = prevState.addedItems
            let removed = addedItems.splice(id, 1)
            addedItems.splice(id + 1, 0, removed[0])
            this.rearranged = true
            this.props.rearranged(addedItems)
          })
        break
    }
  }

  tableBoxes = tHeight => {
    let { addedItems } = this.state
    if (typeof addedItems !== 'undefined') {
      if (typeof addedItems.id !== 'undefined') {
        addedItems = [addedItems]
      } else if (typeof addedItems[0] !== 'undefined') {
        if (typeof addedItems[0][0] !== 'undefined') {
          addedItems = addedItems[0]
        }
      }
    }
    if (addedItems.length > 0) {
      const boxes = []
      {
        const { length } = addedItems
        let addedMinDur = 0
        if (this.checkArray.length < length) {
          ;(this.checkArray = []).length = length
          this.checkArray.fill(1)
        }

        addedItems.map((item, i) => {
          const minSize = this.calculateMinSize(item.id, item.minDur)
          addedMinDur += item.minDur

          let dragHvr
          if (this.dragHover.pos == i && this.dragHover.toggle) {
            dragHvr = true
          } else {
            dragHvr = false
          }

          let sizeOverride

          if (typeof this.dragSizeOverride !== 'undefined') {
            if (i == this.dragSizeOverride.id) {
              sizeOverride = {
                width: this.dropSizeOverride.width,
                height: this.dropSizeOverride.height
              }
            } else if (i == this.dropSizeOverride.id) {
              sizeOverride = {
                width: this.dragSizeOverride.width,
                height: this.dragSizeOverride.height
              }
            } else {
              sizeOverride = { width: -100, height: -100 }
            }
          }

          let hovered = false
          if (i == this.dragHover.pos) {
            hovered = true
          }

          const background = hovered ? '#e2e2e20a' : '#f0f0f0'

          let defWidth = '0%'

          if (typeof this.keys[i] == 'undefined') {
            if (item.name == 'silence') {
              this.keys[i] = 'itemDropped' + this.props.instrument + "_" + i + "_" + i + "_" + item.id + 'silence'
            } else {
              this.keys[i] = 'itemDropped' + item.id
            }
          }

          if (typeof this.boxWidths[i] == 'undefined') {
            if (typeof this.props.targetWidth !== 'undefined') {
              if (this.props.instrument == 'piano') {
                defWidth = this.props.timeUnit * item.minDur
                this.boxWidths[i] = defWidth
              } else {
                if (typeof item.genDur != 'undefined') {
                  defWidth = this.props.timeUnit * (item.minDur + item.genDur)
                } else {
                  defWidth = this.props.targetWidth
                }

                this.boxWidths[i] = defWidth
              }
            }
          } else {
            defWidth = this.boxWidths[i]
          }

          boxes.push(
            <ItemDropped
              display='flex'
              key={this.keys[i]}
              className='itemDropped'
              id={i}
              height={tHeight}
              name={item.name}
              inst={this.props.instrument}
              index={i}
              item={item}
              handleDrop={this.rearrangeDrop}
              dropped
              checkSizeOf={this.props.checkSizeOf}
              resetCheckSizes={this.props.resetCheckSizes}
              checkWidthsOfBoxes={this.checkWidthsOfBoxes}
              removeItem={this.removeItem}
              minSize={minSize}
              getRef={this.getRef}
              size={sizeOverride}
              rearrange={this.rearrange}
              dragHover={dragHvr}
              hovered={hovered}
              editable={this.props.editable}
              pageN={addedItems.length}
              targetWidth={this.props.targetWidth}
              check={this.checkArray[i]}
              resizeHappened={this.resizeHappened}
              resizeChecked={this.resizeChecked}
              defWidth={defWidth}
              moveOne={this.moveOne}
              style={{
                padding: 'auto',
                MozUserSelect: 'none',
                WebkitUserSelect: 'none',
                msUserSelect: 'none',
                background: { background },
                height: tHeight
              }}
            />
          )
        })

        if (!this.justRemoved) {
          if (addedMinDur > this.props.durationSet) {
            // alert("Duration is too short for the selections you have made for " + this.props.instrument + "!");

            this.props.showDurErr(
              addedMinDur,
              this.props.instrument,
              addedItems.length - 1
            )
            //
          }
        } else {
          this.justRemoved = false
        }
      }

      this.checkSizes = false

      this.lastTimeUnit = this.props.timeUnit
      return boxes
    }
    return <div />
  }

  calculateMinSize = (id, minDur) => {
    const minSize = minDur * this.props.timeUnit

    this.totalSize += minSize
    this.totalTime += minDur

    return minSize
  }

  setItems = () => {
    if (this.state.addedItems != this.props.addedItems) {
      this.setState({ addedItems: this.props.addedItems })
      this.props.selsAdded()
    }
  }

  UNSAFE_componentWillReceiveProps (props) {
    if (this.props.addedItems.length > 0) {
      this.setItems()
    }
    this.setState({ refresh: true })
    if (props.editable != this.props.editable) {
      this.shouldUpdate = true
    } else if (props.editable == false) {
      this.shouldUpdate = false
    }

    if (props.playMode == true) {
      this.shouldUpdate = true
    }

    if (typeof props.addedItems !== 'undefined') {
      // this.setItems();
    }

    if (props.itemToRemove >= 0) {
      this.removeItem(props.itemToRemove)
      this.justRemoved = true
    }
  }

  resizeHappened = (id, size) => {
    this.boxWidths[id] = size
  }

  resizeChecked = id => {}

  render () {
    const { connectDropTarget, hovered, canDrop, item, hoverOverride } =
      this.props
    const canvasFlipA = 5
    const canvasFlipB = 4
    const shadowBox_hover = hovered
      ? 'inset 0px 0px 7px 0px #d0d6dd'
      : '0px 0px'
    const pageWidth = `${100 / this.props.addedItems.length}%`
    const tableHeight = `${250 - this.props.boxHeight}px`
    return connectDropTarget(
      <div
        className='target'
        id={this.targetID}
        style={{
          backgroundColor: '#ffffff',
          // backgroundImage: `url(${"data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23727272' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E"})`,
          boxShadow: shadowBox_hover,
          display: 'flex',
          width: '100%',
          minHeight: tableHeight,
          margin: '0px auto',
          border: 'solid 1px #888'
        }}
      >
        {this.state.addedItems.length < 1 && (
          <h4
            style={{
              position: 'absolute',
              display: 'block',
              margin: '0',
              lineHeight: tableHeight,
              width: this.props.targetWidth,
              textAlign: 'center',
              height: tableHeight,
              fontWeight: '300',
              color: '#b2b2b2'
            }}
          >
            Drag and drop a Page to add it to the composition
          </h4>
        )}

        {!this.props.playMode ? (
          this.tableBoxes(tableHeight)
        ) : (
          <div style={{ position: 'relative', width: '100%' }}>
            <canvas
              id={`${this.targetID}_T`}
              style={{
                position: 'absolute',
                left: '0',
                top: '0',
                right: '0',
                zIndex: '3',
                height: tableHeight,
                width: '100%'
              }}
            />
            <svg
              id={`${this.targetID}_A`}
              style={{
                position: 'absolute',
                left: '0',
                top: '0',
                right: '0',
                zIndex: '5',
                height: tableHeight,
                width: '100%'
              }}
            />
          </div>
        )}
      </div>
    )
  }
}

export default DropTarget('item', itemTarget, collect)(Target)
