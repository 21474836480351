// Calculate Start Positions Based on Selected Duration

import async from 'async'
// import Axios from 'axios'
import { Run } from './scaling.jsx'
import {
  fileTimingsJSON,
  instrumentPagesJSON
} from '../../../static/appContext.js'

import fileTimings from '../../../static/res/info/fileTimings.json'
import instrumentPages from '../../../static/res/info/instrumentPages.json'

export function Arrange (selections, outerCallback, reject) {
  async.waterfall(
    [
      callback => {
        // Collect file durs and start positions.
        async.parallel(
          {
            durs (call) {
              call(null, fileTimings);
              //getApi(call, fileTimingsJSON)
            },
            soundEvents (call) {
              call(null, instrumentPages);
              //getApi(call, instrumentPagesJSON)
            }
          },
          (err, results) => {
            if (err) {
              console.log(err)
              return
            }
            callback(null, results)
          }
        )
      },
      (data, next) => {
        const arrangedSelections = []
        selections.map(selection => {
          const inst = selection.name.replace(/\s/g, '')

          let selectionPages
          if (typeof selection.pages.length === 'undefined') {
            selectionPages = [selection.pages]
          } else if (Array.isArray(selection.pages[0])) {
            selectionPages = selection.pages[0]
          } else {
            selectionPages = selection.pages
          }

          const selPages = selectionPages.map(sel => sel.id - 1)
          const instIndex = data.soundEvents.info
            .map(part => part.part)
            .indexOf(inst)
          if (instIndex < 0) {
            _callback(
              'Error: Could not find part... internal error contact Huddersfield Uni',
              null
            )
          }

          /*
           * Var tempRatios = data.soundEvents.info[instIndex].pages.map((page, i) => {
           *     if (selPages.includes(i)) {
           *         return page;
           *     }
           * });
           */
          const tempRatios = []

          for (var i in selPages) {
            if (selPages[i] == -1) {
              tempRatios.push({
                part: inst,
                absoluteNumber: -1,
                relativeNumber: -1,
                soundEvents: []
              })
            } else {
              tempRatios.push(
                data.soundEvents.info[instIndex].pages[selPages[i]]
              )
            }
          }
          const startRatios = []
          for (var i in tempRatios) {
            if (typeof tempRatios[i] !== 'undefined') {
              startRatios.push(tempRatios[i])
            }
          }
          const tempDurs = data.durs[inst]
          const durs = []
          for (var i in selPages) {
            durs.push(tempDurs[selPages[i]])
          }
          const sRs = []

          for (var i in selPages) {
            sRs[i] = startRatios[i].soundEvents.map(sR => sR.start)
          }
          const scheduleData = []
          const indexs = selPages.map((page, i) => i)
          async.forEach(
            indexs,
            (i, step) => {
              async.waterfall(
                [
                  _step => {
                    if (inst == 'piano') {
                      _step(null, {
                        selPageDur: selection.durs[i],
                        startPs: [0]
                      })
                    } else {
                      if (selPages[i] == -1) {
                        _step(null, {
                          selPageDur:
                            Number(
                              `${Math.ceil(`${selection.durs[i]}e2`)}e-2`
                            ) + 0.1,
                          startPs: [],
                          durs: []
                        })
                      } else {
                        Run(
                          sRs[i],
                          durs[i],
                          selectionPages[i].minDur,
                          selection.durs[i],
                          _step,
                          inst
                        )
                      }
                    }
                  },
                  (data, _step) => {
                    scheduleData[i] = {
                      inst,
                      page: selPages[i],
                      data
                    }
                    _step()
                  }
                ],
                (err, result) => {
                  if (err) {
                    console.log(err)
                  }
                  step()
                }
              )
            },
            (err, data) => {
              if (err) {
                console.log(err)
                next(err, null)
              } else {
                arrangedSelections.push(scheduleData)
              }
            }
          )
        })
        next(null, arrangedSelections)
      }
    ],
    (err, result) => {
      if (err) reject(err)
      else outerCallback(result)
    }
  )
}

function getApi (callback, getUrl) {
  Axios.get(getUrl, {
    responseType: 'json',
    validateStatus (status) {
      return status >= 200 && status < 300 // Default
    }
  })
    .then(response => {
      const contentType = response.headers['content-type']

      callback(null, response.data)
    })
    .catch(err => {
      /*
       * Console.log("Could not connect to File Server...\nRetrying...\nConnect Count: " + that.tries);
       * console.log("attempting to get: " + getUrl);
       */
      console.log(err)
      callback(err, null)
    })
}
